import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { log, getShortName } from '../../modules/helpers'

import { Pressable } from 'react-native'

// Components
import Navigation from '../stateful/common/navigation'
import { Container, Main, View, HeroHeader, TabCard, Text, Image, PersonCard, Avatar, Modal } from '../stateless/common/generic'

// Images
import GroupRunning from '../../../assets/images/HardlopenvoorBedrijven-25.jpg'
import RunnersWithTrainer from '../../../assets/group-running-with-coach.jpg'
import PlayIconGrey from '../../../assets/play-icon-grey.svg'
import Crown from '../../../assets/crown.svg'
import QuestionMark from '../../../assets/question-button.svg'

// Data
import app from '../../modules/firebase/app'
import { useHistory } from '../../routes/router'


export const LeaderboardPage = ( { ...props } ) => {

	// Relevant data
	const theme = useSelector( state => ( state.settings?.theme || {} ) )
	const user = useSelector( store => store.user || {} )
	const { score, avatar } = user
	const leaderboard = useSelector( store => store.leaderboard || [] )
	const history = useHistory( )

	// Split leaderboard
	const firstThree = leaderboard.slice( 0, 3 )
	const exclusingFirstThree = leaderboard.slice( 3, leaderboard.length )

	// Control modal
	const [ modalOpen, setModal ] = useState( true )
	const toggleModal = f => setModal( !modalOpen )

	// Get leaderboard data
	useEffect( f => {
		if( user ) app.listenToLeaderboardIfNeeded()
	}, [ user ] )


	return <Container>
		<Modal visible={ modalOpen } onDismiss={ toggleModal }>
			<Text style={ { ...theme?.text?.title, marginBottom: 20 } }>Hoe werkt het Leaderboard?</Text>
			<Text style={ { ...theme?.text?.p, lineHeight: theme?.text?.p?.fontSize * 1.2 } }>
				De score is opgebouwd uit:
				{ `\n\n` }
				- Een groepstraining bijwonen geeft je 100 punten
				{ `\n` }
				- Een afgeronde training uit een schema geeft je 25 punten
				{ `\n\n` }
				Na elke maand wordt de score weer teruggebracht tot 0 en maak jij opnieuw kans om je collega's te verslaan op het leaderboard.
			</Text>
		</Modal>
		<Navigation title={ `Leaderboard` } />
		<Main.Top>
			
			<View>
				
				<HeroHeader onBack={ f => history.goBack() } style={ { } } image={ GroupRunning } />

				<TabCard isBlue={ true } style={ {  } }  title="Leaderboard" >

					<Text style={{ ...theme?.text?.p, paddingHorizontal: 20, paddingBottom: 30 }}>
						Ga de uitdaging aan met je collega’s op het leaderboard. Hoe vaker je meedoet hoe hoger je eindigt.
						<Pressable onPress={ toggleModal }>
							<Image style={ { paddingLeft: 5 } } Source={ QuestionMark } />
						</Pressable>
					</Text>

					{/* Top 3 view */}
					<TopThreeLeaderboard />

					{ exclusingFirstThree.map( ( runner, index ) => <LeaderBoardView name={ runner.name } avatar={ runner.avatar } score={ runner.score } position={ index + 4 } key={ runner.id } />  )}

				</TabCard>

			</View>

		</Main.Top>
	</Container>

}

export const TopThreeLeaderboard = ( { style, ...props } ) => {

	const user = useSelector( store => store.user || {} )
	const leaderboard = useSelector( store => store.leaderboard || [] )

	// Shorten names
	const short = name => getShortName( name, [{width:300,length:4}, {width:340,length:5}, {width:380,length:6}] )

	// Split leaderboard
	const firstThree = leaderboard.slice( 0, 3 )

	// Get leaderboard data
	useEffect( f => {
		if( user ) app.listenToLeaderboardIfNeeded()
	}, [ user ] )

	// Fill up leaderboard with demo data just in case
	const demoPerson = f => ( { name: 'Anoniem', score: 0, id: Math.random() } )
	log( firstThree, firstThree?.length )
	while( firstThree?.length < 3 ) {
		log( 'Add demo person to ', firstThree )
		firstThree.push( demoPerson() )
	}

	return <View style={ { ...style, flexDirection: 'row', paddingHorizontal: 10 } }>
		{ firstThree.map( ( runner, index ) => <TopThreeView name={ short( runner.name ) } avatar={ runner.avatar }  score={ runner.score } position={ index + 1 } key={ runner.id } />  )}
	</View>
}

export const TopThreeView = ( { name, avatar, score, position } ) => {

	const theme = useSelector( state => ( state.settings?.theme || {} ) )
	const avatarSize = 50

	return <View nativeID={ `leaderboard-position-${ position }` } style={{ flexGrow: 1, maxWidth: '30%', margin: '1%', justifyContent: 'flex-end', textAlign: 'center' }}>
		{/* Bar container */}
		<View style={{ 
			width: '100%',
			backgroundColor: position == 1 ? theme.colors?.accent : theme.colors?.primary, 
			height: 50 + ((4 - parseInt(position)) * 50),
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'flex-end',
			padding: 10,
			marginTop:  avatarSize + 5,
			position: 'relative'
		}}>
			{ /* Crown if winner */ }
			{ position == 1 && <Image style={ { position: 'absolute', top: -1 * avatarSize - 5 } } Source={ Crown } /> }

			{/* Avatar */}
			<Avatar name={ name } avatar={ avatar?.uri } size={ avatarSize } style={ {
				position: 'absolute', borderWidth: 2, borderColor: 'white', top: -0.5 * avatarSize,
				marginRight: 0,
				backgroundColor: position == 1 ? theme.colors?.primary : theme.colors?.accent
			} }  />
			<Text style={{ ...theme.text?.bold, color: 'white' , fontSize: 22 }}>{ position }</Text>
			<Text style={{ ...theme.text?.p, color: 'white' , fontSize: 20 }}>{ score }</Text>
		</View>
		<Text style={{ paddingVertical: 10, ...theme?.text?.bold, textTransform: 'uppercase' }}>{ name }</Text>
	</View>

}

export const LeaderBoardView = ({ name, avatar, score, position }) => {

	const theme = useSelector( state => ( state.settings?.theme || {} ) )

	return <View style={{ paddingHorizontal: 20 }}>
		<View style={{ 
			flexDirection: 'row', 
			justifyContent: 'space-between',
			backgroundColor: theme.colors?.primary,
			padding: 10,
			marginTop: 10,
			borderRadius: 10,
			paddingBottom: 30,
			marginBottom: -30
		}}>
			<Text style={{ ...theme.text?.bold, color: 'white', fontWeight: 'bold' }}>{ position }</Text>
			<View style={{ flexDirection: 'row' }}>
				<Text style={{ ...theme.text?.bold, color: 'white' }}>SCORE </Text>
				<Text style={{ ...theme.text?.label, color: 'white' }}>{ score }</Text>
			</View>
		</View>
		<PersonCard name={ name } avatar={ avatar?.uri } />
	</View>

}