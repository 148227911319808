import React from 'react'

// Visual
import { Component, Container, Loading, Main, Text, View, Input, Button } from '../../stateless/common/generic'
// import Navigation from '../common/navigation'
import { Register, Login, Forgot, OpenYourEmail } from '../../stateless/onboarding/login-and-registration'

// Data
import { log, catcher } from '../../../modules/helpers'
import app from '../../../modules/firebase/app'

// Redux
import { connect } from 'react-redux'

class LoginAndRegistration extends Component {

	state = {
		loading: false,
		input: {
			uid: this.props?.match?.params?.uid || '',
			name: this.props?.match?.params?.name || '',
			email: '',
			password: '',
			code: this.props?.match?.params?.code || ''
		},
		action: this.props?.match?.params?.action || 'login'
	}

	onInput = ( key, value='' ) => {

		const { input } = this.state

		return this.updateState( { input: { ...input, [key]: value } } )

	}

	onError = error => this.updateState( { error: error } )

	setAction = action => this.updateState( { action: action } )


	onSubmit = async f => {

		log( 'Submitting with ', this.state )

		const { action, input, error } = this.state
		const { history } = this.props
		let { uid, name, email, password, code } = input

		// Trimming
		name = name?.trim()
		email = email?.trim()
		code = code?.trim()

		// If there is a validation error
		if( error ) return alert( 'Vul aub alle velden goed in' )

		// If something relevant is missing
		if( action == 'register' && ( !name || !email || !password || ( !uid && !code ) ) ) return alert( 'Vul aub alle velden in' )
		if( action == 'login' && ( !email || !password ) ) return alert( 'Vul aub uw email en wachtwoord in' )
		if( action == 'forgot' && !email ) return alert( 'Vul aub uw email in' )

		// Take the async actions
		try {

			// Show loading screen
			if( action == 'forgotpassword' ) await this.updateState( { loading: 'Email versturen...' } )
			else await this.updateState( { loading: `Gebruiker ${ action == 'register' ? 'registreren' : 'inloggen' }` } )

			// Do the relevant action
			if( action == 'login' ) {
				const res = await app.loginUser( email, password )
				log( res )
			}
			if( action == 'register' ) {

				// Register new regular user
				if( !uid ) await app.registerUser( name, email, password, code )

				// Register trainer ( uid was supplied )
				else await app.registerUserWithUid( name, email, password, uid )
					
			}
			if( action == 'forgotpassword' ) {
				await app.resetPassword( email )
				return this.updateState( { action: 'openemail' } )
			}

			// After login/reg open profile
			history.push( '/' )

		} catch( e ) {

			log( 'Login error: ', e.message || JSON.stringify( e ) )
			log( 'error code', e.code || "no code" )

			if( e.code === "auth/wrong-password" || e.code === "auth/user-not-found" ) e.message = ( action == 'forgotpassword' ? "Het opgegeven e-mailadres is niet gevonden" : "De ingevoerde combinatie van e-mail en wachtwoord is niet juist." )
			if( e.code === "auth/too-many-requests"  ) e.message = "De toegang tot dit account is tijdelijk geblokkeerd door te veel foutieve inlogpogingen. Je kunt dit account direct herstellen door het wachtwoord te resetten of je kunt het later opnieuw proberen."
			if( e.code === "invalid-argument" && action === 'register' ) e.message = "Ongeldige aanmeldingscode"

			alert( `Fout: ${ e.message || JSON.stringify( e ) }` )

		} finally {
			await this.updateState( { loading: false } )
		}
		

	}

	render() {

		const { loading, input, action } = this.state

		if( loading ) return <Loading message={ loading } />

		return <Container>
			{ /* <Navigation title='' /> */ }

			<Main.Top>

				{ action == 'register' && <Register onError={ this.onError } onSubmit={ this.onSubmit } setAction={ this.setAction } input={ input } onInput={ this.onInput } /> }
				{ action == 'login' && <Login onError={ this.onError } onSubmit={ this.onSubmit } setAction={ this.setAction } input={ input } onInput={ this.onInput } /> }
				{ action == 'forgotpassword' && <Forgot onError={ this.onError } onSubmit={ this.onSubmit } setAction={ this.setAction } input={ input } onInput={ this.onInput } /> }
				{ action == 'openemail' && <OpenYourEmail onError={ this.onError } setAction={ this.setAction } /> }

			</Main.Top>
		</Container>

	}

}

export default connect( store => ( {
	user: store.user
} ) )( LoginAndRegistration )