import React, { useState, useEffect } from 'react'
import { Pressable } from 'react-native'
import { Text, View, TabCard, Image, Button, Card, HeroHeader, TabHeading } from '../common/generic'

// Data
import { useSelector } from 'react-redux'
import { log, safeSort } from '../../../modules/helpers'
import { width, widthListener } from '../../../modules/apis/platform'
import app from '../../../modules/firebase/app'
import { useHistory } from '../../../routes/router'

// Assets
// - Images
import GroupRunningFromSide from '../../../../assets/images/HardlopenvoorBedrijven-01.jpg'
import GroupRunningAlt from '../../../../assets/images/HardlopenvoorBedrijven-10.jpg'
import GroupRunningWithTrainerAlt from '../../../../assets/images/HardlopenvoorBedrijven-04.jpg'

import ManRunningAlone from '../../../../assets/man-running-alone.jpg'

// - Grahpics
import AddBall from '../../../../assets/add-ball.svg'
import AddBallWhite from '../../../../assets/add-ball-white.svg'
import Clock from '../../../../assets/clock.svg'
import ArrowLeft from '../../../../assets/arrow-left.svg'
import PlayIconGrey from '../../../../assets/play-icon-grey.svg'
import PlayIconBlue from '../../../../assets/play-icon-blue.svg'
import CheckmarkGreen from '../../../../assets/check-icon-green.svg'


// ///////
// Helpers
// ///////

// Lower weeks go up
const sortByWeek = ( one, two ) => safeSort( one[ 'workout-week' ]?.value - two[ 'workout-week' ]?.value )
// Same week lower prio goes up
const sortWithinWeek = ( one, two ) => safeSort( ( one[ 'workout-week' ]?.value == two[ 'workout-week' ]?.value ) && one[ 'workout-priority' ]?.value - two[ 'workout-priority' ]?.value )
// Find workouts for schedule
// const findWorkouts = ( schema, workout ) => {

// 		const matches = []

// 		for ( let i = schema[ 'schema-goal' ]?.value?.length - 1; i >= 0; i-- ) {
// 			// If one of the schema goals matches on of the workout goals
// 			if( workout[ 'workout-goal' ]?.value?.includes( schema[ 'schema-goal' ]?.value[ i ] )  ) matches.push( 'goal' )
// 		}
		
// 		if( workout[ 'workout-distance' ]?.value?.includes( schema[ 'schema-distance' ]?.value ) ) matches.push( 'distance' )
// 		if( workout[ 'workout-week-freq' ]?.value?.includes( schema[ 'schema-frequency' ]?.value ) ) matches.push( 'frequency' )
// 		return matches.length == 3

// }

// ///////////////////////////////
// All schemas overview
// ///////////////////////////////
export const AllSchemas = ( { ...props } ) => {

	const theme = useSelector( state => ( state.settings?.theme || {} ) )
	const { raw=[] } = useSelector( state => ( state.schemas || {} ) )
	const { goals={} } = useSelector( state => ( state.user || {} ) )
	const [ activeFilter, setFilter ] = useState( 'recommended' )

	const history = useHistory(  )

	// Image cutoff management
	const [ currentWidth, setWidth ] = useState( width() )
	useEffect( f => widthListener( setWidth ) )

	// Sort schemas based on distance and frequency
	const sortedSchemas = [...raw].sort( (a, b) => parseInt( a['schema-distance'].value ) - parseInt( b['schema-distance'].value ) || parseInt( a['schema-frequency'].value ) - parseInt( b['schema-frequency'].value ) )

	// Calculate the best schemas
	const rankedSchemas = raw.map( schema => {
		
		const rankedSchema = { ...schema, ranking: 0 }

		// If this schema adheres to user distance, upvote
		if( rankedSchema[ 'schema-distance' ]?.value == goals.distance ) rankedSchema.ranking += 1
		
		// For each goal the schema and the user have in common, upvote
		goals.focuslist?.map( focusGoal => {
			if( rankedSchema[ 'schema-goal' ]?.value?.includes( focusGoal ) ) rankedSchema.ranking += 1
		} )

		return rankedSchema
	} ).sort( ( one, two ) => one.ranking - two.ranking ).slice( 0, 3 ) // Result of sort compare function shows array direction. A -something means make index lower ( higher in array )

	log( "raw", raw )
	log( "sorted", sortedSchemas )
	
	return <View>

		<HeroHeader image={ currentWidth < 720 ? GroupRunningWithTrainerAlt : GroupRunningWithTrainerAlt } onBack={ f => history.goBack() } />

		{ /*
			////////////////////
			// Schemas
			////////////////////
		*/ }
		<TabCard style={ { paddingBottom: 20 } } title={ activeFilter == 'all' ? "Alle schema's" : "Aanbevolen schema's" }>
			
			{ /* Relevant schema cards */ }
			{ activeFilter == 'all' && sortedSchemas.map( ( { id } ) => <SchemaCard key={ id } schemaIdToShow={ id } /> ) }
			{ activeFilter == 'recommended' && rankedSchemas.map( ( { id } ) => <SchemaCard key={ id } schemaIdToShow={ id } /> ) }

			{ /* Registration button */ }
			<Pressable onPress={ f => setFilter( activeFilter == 'all' ? 'recommended' : 'all' ) } style={ {
				backgroundColor: theme.colors?.divider,
				flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: 10, marginHorizontal: 10,
				borderBottomRightRadius: 10, borderBottomLeftRadius: 10, borderTopLeftRadius: 4, borderTopRightRadius: 4, marginTop: 20
			} }>
				<Text style={ { ...theme.text?.bold, color: theme.colors?.text, fontSize: 12 } }>Bekijk { activeFilter == 'all' ? 'aanbevolen' : 'alle' } schema's</Text>
				<Image style={ { height: 13, width: 13, marginLeft: 10 } } Source={ AddBall } />
			</Pressable>
		</TabCard>
	</View>
}

// ///////////////////////////////
// Single schema view
// ///////////////////////////////
export const SingleSchema = ( { schemaIdToShow, ...props } ) => {

	// Create listener for this schema if if doesn't exist yet
	app.addWorkoutListenerBySchemaIdIfNeeded( schemaIdToShow ).catch( e => log( e ) )

	// Redux data
	const { raw=[] } = useSelector( state => ( state.schemas || {} ) )
	const { enrolledSchemas=[] } = useSelector( state => ( state.user || {} ) )
	const workouts = useSelector( state => ( state.workouts || [] ) )
	const schema = raw?.find( ( { id } ) => id == schemaIdToShow )
	let amRegistered = !!enrolledSchemas?.includes( schemaIdToShow )
	const theme = useSelector( state => ( state.settings?.theme || {} ) )
	// const exampleWorkout = schema && workouts.find( workout => {

	// 	const matches = []

	// 	for ( let i = schema[ 'schema-goal' ]?.value?.length - 1; i >= 0; i-- ) {
	// 		// If one of the schema goals matches on of the workout goals
	// 		if( workout[ 'workout-goal' ]?.value?.includes( schema[ 'schema-goal' ]?.value[ i ] )  ) matches.push( 'goal' )
	// 	}
		
	// 	if( workout[ 'workout-distance' ]?.value?.includes( schema[ 'schema-distance' ]?.value ) ) matches.push( 'distance' )
	// 	if( workout[ 'workout-week-freq' ]?.value?.includes( schema[ 'schema-frequency' ]?.value ) ) matches.push( 'frequency' )
	// 	return matches.length == 3
	// } )

	// Nav
	const history = useHistory(  )

	// Image cutoff management
	const [ currentWidth, setWidth ] = useState( width() )
	useEffect( f => widthListener( setWidth ) )

	// Registration
	const [ isRegistering, toggleRegistering ] = useState( false )
	const registerForSchema = async f => {

		toggleRegistering( true )

		// get workout associated with this schema
		const workoutIds = amRegistered && workouts.filter( workout => workout.schemas?.includes( schemaIdToShow ) ).map( ( { id } ) => id )
		
		// unregister
		await ( !amRegistered ? app.registerForSchema( schemaIdToShow ) : app.unregisterForSchema( schemaIdToShow, workoutIds ) )
		toggleRegistering( false )
		amRegistered = !amRegistered

	}

	if( !schema ) {
		log( 'SingleSchema called without session data' )
		return null
	}

	return <View>

		<HeroHeader image={ currentWidth < 720 ? GroupRunningFromSide : GroupRunningFromSide } onBack={ f => history.goBack() } />

		<TabCard style={ { paddingBottom: 40 } } tags={ schema[ 'schema-goal' ]?.value } >

			<TabHeading>{ schema[ 'schema-title' ]?.value }</TabHeading>

			<Text style={ { ...theme.text?.bold, paddingHorizontal: 20, fontSize: 16, textTransform: 'uppercase', marginBottom: 10 } }>Voor jou</Text>
			<Text style={ { ...theme.text?.p, paddingHorizontal: 20, lineHeight: 28, marginBottom: 40 } }>{ schema[ 'schema-targetgroup' ]?.value }</Text>

			<Text style={ { ...theme.text?.bold, paddingHorizontal: 20, fontSize: 16, textTransform: 'uppercase', marginBottom: 10 } }>Opbouw</Text>
			<Text style={ { ...theme.text?.p, paddingHorizontal: 20, lineHeight: 28 } }>{ schema[ 'schema-opbouw' ]?.value }</Text>

			<Button
				noChevron={ amRegistered }
				onPress={ registerForSchema }
				style={ {
					borderRadius: 20, paddingVertical: 5, alignSelf: 'center', marginTop: 20, width: '90%',
					backgroundColor: amRegistered ? 'none' : theme.colors?.primary,
					borderColor: theme.colors?.primary, borderBottomColor: theme.colors?.primary,
					borderWidth: 2
				} }
				labelStyle={ { fontSize: 16, color: amRegistered ? theme.colors?.primary : 'white' } }
				label={ isRegistering ? 'Opslaan...' : `${ amRegistered ? 'Stop' : 'Start' } het schema` } 
			/>
			
		</TabCard>

		{ <TabCard isBlue={ true } style={ { marginTop: 0 } } title="Uitleg van jouw training" >

			<Text style={ { ...theme.text?.p, paddingLeft: 20, lineHeight: 28, marginBottom: 40 } }>{ schema[ 'schema-uitleg' ]?.value || 'Dit schema heeft nog geen uitleg.' }</Text>
			
			<Button
				onPress={ f => history.push( `/schemas/workouts/${ schema.id }` ) }
				style={ { borderRadius: 20, paddingVertical: 5, alignSelf: 'center', marginVertical	: 20, width: '90%' } }
				labelStyle={ { fontSize: 16 } }
				label='Bekijk het schema'
			/>

		</TabCard> }


	</View>
}

// ///////////////////////////////
// Workouts overview
// ///////////////////////////////
export const Workouts = ( { schemaIdToShow, ...props } ) => {

	// Create listener for this schema if if doesn't exist yet
	app.addWorkoutListenerBySchemaIdIfNeeded( schemaIdToShow ).catch( e => log( e ) )

	// Redux data
	const { raw=[] } = useSelector( state => ( state.schemas || {} ) )
	const { enrolledSchemas=[], completedWorkouts=[] } = useSelector( state => ( state.user || {} ) )
	const workouts = useSelector( state => ( state.workouts || [] ) )
	const theme = useSelector( state => ( state.settings?.theme || {} ) )

	// Filtered data
	const schema = raw?.find( ( { id } ) => id == schemaIdToShow )
	let amRegistered = !!enrolledSchemas?.includes( schemaIdToShow )
	const workoutsInSchema = schema && workouts.filter( workout => workout[ 'workout-schema' ]?.value == schemaIdToShow ).sort( sortByWeek ).sort( sortWithinWeek )

	log( `Workouts in schema ${ schemaIdToShow } `, schema, ` are: `, workoutsInSchema )

	// Dynamic data
	let nextWorkout = undefined

	// Nav
	const history = useHistory(  )

	// Image cutoff management
	const [ currentWidth, setWidth ] = useState( width() )
	useEffect( f => widthListener( setWidth ) )

	// Registration
	const [ isRegistering, toggleRegistering ] = useState( false )
	const registerForSchema = async f => {
		toggleRegistering( true )
		await ( !amRegistered ? app.registerForSchema( schemaIdToShow ) : app.unregisterForSchema( schemaIdToShow, workoutsInSchema.map( ( { id } ) => id ) ) )
		toggleRegistering( false )
		amRegistered = !amRegistered
	}

	if( !schema ) {
		log( 'Workouts called without session data' )
		return null
	}

	return <View>

		<HeroHeader image={ currentWidth < 720 ? GroupRunningAlt : GroupRunningAlt } onBack={ f => history.goBack() } />

		<TabCard style={ { paddingBottom: 40 } } tags={ schema[ 'schema-goal' ]?.value } >

			<TabHeading>Hardloopschema: { schema[ 'schema-title' ]?.value }</TabHeading>
			
			{ /* List the workouts */ }
			{ workoutsInSchema?.map( ( workout, index ) => {

				const workoutComplete = completedWorkouts?.includes( workout.id )

				// Manage next workout status
				if( !workoutComplete && !nextWorkout ) nextWorkout = workout.id
				const isNextWorkout = nextWorkout == workout.id

				// Dynamic styling
				const iconToUse = ( workoutComplete && CheckmarkGreen ) || ( isNextWorkout && PlayIconBlue ) || PlayIconGrey
				const borderColor = ( workoutComplete && theme?.colors?.labels?.success ) || ( isNextWorkout && theme?.colors?.primary ) || theme?.colors?.divider
				const backgroundColor = ( workoutComplete && 'rgba( 141,198,63,0.2 )' ) || ( isNextWorkout && 'rgba( 227,245,255,1 )' ) || 'none'

				return <Pressable
							nativeID={ `workout-${ index }-${ workoutComplete ? 'complete' : 'incomplete' }` }
							onPress={ f => history.push( `/schemas/singleworkout/${ workout.id }` ) }
							style={ {
								borderBottomWidth: 4, borderWidth: 1, borderRadius: 10, borderColor: borderColor,
								backgroundColor: backgroundColor,
								padding: 10, marginTop: 10, marginHorizontal: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'
							} } key={ workout.id }
						>
					<Text style={ { ...theme?.text?.label } } >Week { workout[ 'workout-week' ]?.value } - { workout[ 'workout-title' ]?.value }</Text>
					<Image style={ { marginLeft: 'auto', height: 22, width: 22 } } Source={ iconToUse } />
				</Pressable>

			} ) }

			{ /* Registration button */ }
			<Pressable onPress={ registerForSchema } style={ {
				backgroundColor: amRegistered ? theme.colors?.labels?.neutral : theme.colors?.labels?.success,
				flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: 10, marginHorizontal: 10,
				borderBottomRightRadius: 10, borderBottomLeftRadius: 10, borderTopLeftRadius: 4, borderTopRightRadius: 4, marginTop: 20
			} }>
				<Text style={ { ...theme.text?.bold, color: 'white', fontSize: 12 } }>{ amRegistered ? 'Af' : 'Aan' }melden voor schema</Text>
				{ !amRegistered && <Image style={ { height: 13, width: 13, marginLeft: 10 } } Source={ AddBallWhite } /> }
			</Pressable>


		</TabCard>

	</View>
}

// ///////////////////////////////
// Single workout view
// ///////////////////////////////
export const Workout = ( { workoutIdToShow, ...props } ) => {

	// Redux data
	const workouts = useSelector( state => ( state.workouts || [] ) )
	const workout = workouts?.find( ( { id } ) => id == workoutIdToShow )
	const { completedWorkouts=[] } = useSelector( state => ( state.user || {} ) )
	let isCompleted = !!completedWorkouts?.includes( workoutIdToShow )
	const theme = useSelector( state => ( state.settings?.theme || {} ) )

	// Nav
	const history = useHistory(  )

	// Registration
	const [ isCompleting, toggleCompleting ] = useState( false )
	const completeWorkout = async f => {
		toggleCompleting( true )
		await app.completeWorkout( workoutIdToShow )
		toggleCompleting( false )
		isCompleted = true
		history.goBack()
	}

	if( !workout ) {
		log( 'Workout called without session data' )
		return null
	}

	return <View>

		<HeroHeader image={ GroupRunningWithTrainerAlt } onBack={ f => history.goBack() } />

		<TabCard style={ { paddingBottom: 40 } } tags={ workout[ 'workout-goal' ]?.value } >

			<TabHeading>{ workout[ 'workout-title' ]?.value }</TabHeading>
			<WorkoutDetails isBasic={ true } workout={ workout } />
			
		</TabCard>

		<TabCard isBlue={ true } title="Uitleg van jouw training">
			<Text style={ { ...theme.text?.p, paddingLeft: 20, lineHeight: 28, marginBottom: 40 } }>{ workout[ 'workout-speed' ]?.value }</Text>
			<Button
				onPress={ isCompleted ? undefined : completeWorkout }
				style={ { borderRadius: 20, paddingVertical: 5, alignSelf: 'center', marginVertical	: 20, width: '90%', backgroundColor: isCompleted ? theme.colors?.labels?.success : theme.colors?.primary } }
				labelStyle={ { fontSize: 16 } }
				label={ isCompleting ? `Opslaan...` : `Training ${ isCompleted ? 'is afgerond' : 'afronden' }` }
				noChevron={ isCompleted }
			/>
		</TabCard>

	</View>
}

// ///////////////////////////////
// Cards
// ///////////////////////////////
const SchemaCard = ( { schemaIdToShow } ) => {


	// Redux data
	const { raw=[] } = useSelector( state => ( state.schemas || {} ) )
	const { enrolledSchemas=[] } = useSelector( state => state.user || {} )
	const workouts = useSelector( state => state.workouts || [] )
	const theme = useSelector( state => ( state.settings?.theme || {} ) )
	// Filtered data
	const schema = raw?.find( ( { id } ) => id == schemaIdToShow )
	let amRegistered = !!enrolledSchemas?.includes( schemaIdToShow )
	
	const history = useHistory()
	const verticalPadding = 15

	const [ isRegistering, toggleRegistering ] = useState( false )
	const registerForSchema = async f => {
		toggleRegistering( true )
		const workoutIds = amRegistered && workouts.filter( workout => findWorkouts( schema, workout ) ).map( ( { id } ) => id )
		await ( !amRegistered ? app.registerForSchema( schemaIdToShow ) : app.unregisterForSchema( schemaIdToShow, workoutIds ) )
		toggleRegistering( false )
		amRegistered = !amRegistered
	}

	if( !schema ) {
		log( 'SchemaCard called without session data' )
		return null
	}

	return <Card key={ schema.id } style={ { marginBottom: 20, marginTop: 0, marginHorizontal: 10, paddingVertical: 0, paddingHorizontal: 0, flexDirection: 'row', minHeight: 142 } }>
		<View style={ { flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', flex: 1, paddingHorizontal: 15, paddingVertical: 12 } }>
			
			{ /* Title and badges */ }
			<Text style={ { ...theme.text?.schemaTitle } }>{ schema[ 'schema-title' ]?.value }</Text>
			<View style={ { flexDirection: 'row', marginTop: 8 } }>
				{ schema[ 'schema-goal' ]?.value?.map( goal => <Button
					key={ goal }
					noChevron={ true }
					labelStyle={ { color: theme?.colors?.sub, textTransform: 'uppercase', textAlign: 'center', fontSize: 12 } }
					style={ { backgroundColor: theme?.colors?.background, borderRadius: 20, paddingVertical: 5, paddingHorizontal: 10, borderBottomWidth: 0 } }
					label={ goal }
				/> ) }
			</View>

			{ /* Register/unregister button for schema*/ }
			{ <Button
				onPress={ registerForSchema }
				noChevron={ true }
				labelStyle={ { color: amRegistered ? 'white' : theme?.colors?.labels?.success, textAlign: 'center', fontSize: 12, textTransform: 'uppercase' } }
				style={ {
					backgroundColor: amRegistered ? theme?.colors?.labels?.success : 'none',
					marginTop: 'auto', borderRadius: 20, paddingVertical: 5, borderColor: theme?.colors?.labels?.success, borderBottomColor: theme?.colors?.labels?.success, borderWidth: 1, borderBottomWidth: 1
				} }
				label={ isRegistering ? 'Opslaan...' : `${ amRegistered ? 'Stop' : 'Start' } schema` } 
			/> }


		</View>

		{ /* Bekijken card */ }
		<View style={ { height: '100%', marginLeft: 'auto' } }>
			<Image style={ { height: '100%', width: 'auto' } } Source={ ManRunningAlone } /> 
			<Button onPress={ f => history.push( `/schemas/single/${ schema.id }` ) } labelStyle={ { fontSize: 12 } } style={ { borderRadius: 20, paddingVertical: 5, position: 'absolute', bottom: verticalPadding, alignSelf: 'center' } } label={ `Bekijken` } />
		</View>
		
	</Card>

}

const WorkoutDetails = ( { workout, isBasic=false, ...props } ) => {

	const theme = useSelector( state => ( state.settings?.theme || {} ) )

	if( !workout ) {
		log( 'WorkoutDetails called without session data' )
		return null
	}

	return <View>
		
			<View style={ { flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 20 } } >
				<Image style={ { height: 23, width: 23, paddingLeft: 20 } } Source={ Clock } />
				<Text style={ { ...theme.text?.bold, paddingLeft: 10, fontSize: 16, textTransform: 'uppercase', color: theme.colors?.primary } }>{ workout[ 'workout-duration' ]?.value } Min</Text>
			</View>

			{ !isBasic && <Text style={ { ...theme.text?.bold, paddingLeft: 20, fontSize: 16, textTransform: 'uppercase', marginBottom: 10 } }>{ workout[ 'workout-title' ]?.value }</Text> }
			<Text style={ { ...theme.text?.p, paddingLeft: 20, lineHeight: 28, marginBottom: 40 } }>{ workout[ 'workout-text' ]?.value }</Text>

			{ !isBasic && <View>
				<Text style={ { ...theme.text?.bold, paddingLeft: 20, fontSize: 16, textTransform: 'uppercase', marginBottom: 10 } }>Uitleg van jouw training</Text>
				<Text style={ { ...theme.text?.p, paddingLeft: 20, lineHeight: 28, marginBottom: 40 } }>{ workout[ 'workout-speed' ]?.value }</Text>
			</View> }

	</View>
}