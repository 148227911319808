import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { Helmet } from 'react-helmet'

// Visual
import { Animated, SafeAreaView } from 'react-native'
import { BlackPortal } from 'react-native-portal'
import { Text, Pressable, View, Image } from './generic'
import { PanGestureHandler } from 'react-native-gesture-handler'
import { isWeb, version } from '../../../modules/apis/platform'
import { updateIfAvailable } from '../../../modules/apis/updates'
import Hamburger from '../../../../assets/burger.svg'
import AppLogo from '../../../../assets/app-logo.svg'

// ///////////////////////////////
// Header
// ///////////////////////////////
export const Header = ( { style, back, title, subtitle, toggle, pan, drawer, drawerWidth, drawerTranslate, toggleDark, children, links, go } ) => {

	const theme = useSelector( state => ( state.settings?.theme || {} ) )

	return <View style={ { width: '100%', backgroundColor: theme?.colors?.accent, height: 60 } }>

		<Helmet>
			<meta name="theme-color" content={ theme?.colors?.accent } />
		</Helmet>

		<View style={ { width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: '100%', paddingHorizontal: 15, ...style } } statusBarHeight={ 0 }>
			
			{ /* back && <View onPress={ back } />  */ }

			{ /* Logo */ }
			<Pressable style={ { paddingRight: 10 } } onPress={ f => go( '/' ) }><Image style={ { width: 30, height: 30 } } Source={ AppLogo } /></Pressable>

			{ title && <Text style={ { ...theme.text?.title, fontSize: 20, color: theme.colors?.surface, marginRight: 'auto' } }>{ title }</Text> }

			{ children }

			<Pressable nativeID="navigation-toggle" style={ { marginLeft: 'auto' } } onPress={ toggle }><Image Source={ Hamburger } /></Pressable>

		</View>

		{ drawer && <Menu links={ links } go={ go } toggleDark={ toggleDark } translate={ drawerTranslate } width={ drawerWidth } pan={ pan } toggle={ toggle } /> }
	
	</View>
}

// ///////////////////////////////
// Sidebar
// ///////////////////////////////

// Dark mode
// const DarkMode = ( { toggleDark, theme } ) => <View style={ { flexDirection: 'row', paddingHorizontal: 40, paddingVertical: 20, borderTopWidth: 1, borderTopColor: theme.colors.divider } }>
// 	<View label='Dark mode' onToggle={ toggleDark } value={ theme.dark } />
// </View>

// Backdrop of menu
const Backdrop = ( { children, width, toggle, pan, ...props } ) => <BlackPortal name="menu" style={ { alignItems: 'center', justifyContent: 'center' } }>

	{  /* Touchable backdrop that closes the sidebar */ }
	<Pressable activeOpacity={ 1 } onPress={ toggle } style={ { zIndex: 99, flex: 1, position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,.2)' } }>

		{ /* The actual sidebar */ }
		<Pressable activeOpacity={ 1 } style={ { height: isWeb ? '100vh' : '100%', width: width, maxWidth: '100%', alignSelf: 'flex-end' } }>

			{ /* Animation gesture handler */ }
			<PanGestureHandler onHandlerStateChange={ pan } onGestureEvent={ pan }>

				{ children }

			</PanGestureHandler>

		</Pressable>

	</Pressable>

</BlackPortal>

// ///////////////////////////////
//  Menu
// ///////////////////////////////
export const Menu = ( { width, links, go, toggle, pan, translate, toggleDark, ...props } ) => {

	const theme = useSelector( state => ( state.settings?.theme || {} ) )
	const [ updatesAvailable, setUpdatesAvailable ] = useState( false )
	const [ checkingUpdates, setCheckingUpdates ] = useState( false )
	const [ checkedAt, setCheckedAt ] = useState( undefined )
	const check = async f => {
		setCheckingUpdates( true )
		const available = await updateIfAvailable()
		setUpdatesAvailable( available )
		setCheckingUpdates( false )
		setCheckedAt( `${new Date().getHours()}:${new Date().getMinutes()}` )
	}

	return <Backdrop toggle={ toggle } pan={ pan } width={ width }>
		<Animated.View style={ [ translate, { flex: 1 } ] }>

			{ /* Visual surface element */ }
			<View style={ { flex: 1 } }>
				<SafeAreaView style={ { flex: 1, backgroundColor: theme?.colors?.background } }>

					{ /* Title */ }
					<View nativeID="navigation-surface" style={ { height: '100%', marginBottom: 0 } }>

						{ /* Elements included from above */ }
						{  /*links.map( section => <Drawer.Section style={ { } } key={ section.label } title={ section.label }>
							{ section.links.map( ( { label, to, onPress } ) => <Drawer.Item key={ label+to } label={ label } onPress={ onPress ? onPress : f => go( to ) } /> ) }
						</Drawer.Section> ) */ }
						{ links.map( link => <Pressable key={ link.label } style={ { paddingHorizontal: 20 } } onPress={ link.onPress || ( f => go( link.to ) ) }>
							<Text style={ { ...theme.text.title, fontSize: 20, fontWeight: 700, paddingVertical: 20, borderBottomWidth: 1, borderBottomColor: theme.colors.primary } }>{ link.label }</Text>
						</Pressable> ) }


					    <View style={ { marginTop: 'auto', width: '100%' } }>
					    	{ /* Version info */ }
					    	{ checkingUpdates && <Text style={ { opacity: .3, padding: 10, textAlign: 'right' } }>Checking for updates</Text> }
					    	{ !checkingUpdates && !updatesAvailable && <Text onPress={ check } style={ { opacity: .3, padding: 10, textAlign: 'right' } }>{ version } { updatesAvailable ? '- update available' : '- latest' } { checkedAt && `(checked: ${ checkedAt })` }</Text> }
					    	
					    	{ /* Darkmode toggle */ }
					    	{ /* <DarkMode toggleDark={ toggleDark } theme={ theme } /> */ }
					    </View>

				    </View>
			    </SafeAreaView>
			</View>

		</Animated.View>
	</Backdrop>
}