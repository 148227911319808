import React from 'react'
import { useSelector } from 'react-redux'

import { Text, View, Input, Button, TabCard, ContainerView, Image } from '../../stateless/common/generic'
import BrandingWhite from '../../../../assets/hardlopen-voor-bedrijven-white.svg'
import Branding from '../../../../assets/hardlopen-voor-bedrijven-solid.svg'

export const Register = ( { setAction, onInput, onSubmit, onError, input={}, ...props } ) => {

	// Grab the theme from redux
	const theme = useSelector( state => ( state.settings?.theme || {} ) )
	const { name, email, password, code, uid } = input

	return <React.Fragment>
		
		{ /* Code request */ }
		<ContainerView style={ { backgroundColor: theme.colors?.accent, paddingVertical: 40, paddingHorizontal: 20 } }>
			<Image Source={ BrandingWhite } style={ { alignSelf: 'flex-end', marginBottom: -15, width: 150 } } />

			{uid
				? <Text style={ { ...theme.text?.title, color: 'white' } }>Aanmelden als trainer</Text> 
				: <Text style={ { ...theme.text?.title, color: 'white' } }>Aanmelden</Text> 
			}

			{ /* Not invited user */ }
			{ !uid && <React.Fragment>
				<Text style={ { ...theme.text.p, color: 'white', paddingVertical: 20 } }>Gebruik de aanmeldingscode die je hebt ontvangen van je werkgever.</Text>
				<Input nativeID="register-aanmeldingscode" onError={ onError } value={ code } onChangeText={ t => onInput( 'code', t ) } label="Aanmeldingscode" labelStyle={ { color: 'white' } } style={ { borderBottomColor: "rgba(20, 155, 240, 0.5)" } } placeholder="bv 237823483780" />
			</React.Fragment> }


		</ContainerView>

		{ /* User data */ }
		<ContainerView style={ { flexGrow: 1, paddingVertical: 50, paddingHorizontal: 20, backgroundColor: theme.colors?.background } }>
			<Input nativeID="register-name" onError={ onError } validate=".{2,200}" error="Minimaal 2 karakters, maximaal 200 karakters" value={ name } onChangeText={ t => onInput( 'name', t ) } autoCompleteType='name' label="Naam" placeholder="bv Marc Huizen" />
			<Input nativeID="register-email" onError={ onError } validate="^\S+@\S+\.\S+$" error="Vul aub een geldig email adres in" value={ email } onChangeText={ t => onInput( 'email', t ) } autoCompleteType='email' label="E-mail" placeholder="bv m.huizen@gmail.com" />
			<Input nativeID="register-password" onError={ onError } validate=".{8,100}" error="Minimale wachtwoord lengte is 8" value={ password } onChangeText={ t => onInput( 'password', t ) } autoCompleteType='password' label="Wachtwoord" placeholder="*********" secureTextEntry={ true } />
			<View style={ { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' } }>
				<Button nativeID="register-submit" onPress={ onSubmit } label='Aanmelden' />
				<Text nativeID="register-togglelogin" style={ { ...theme.text?.sub, color: theme.colors?.label?.neutral, fontWeight: 'bold' } } onPress={ f => setAction( 'login' ) }>Ben je al lid?</Text>
			</View>
		</ContainerView>	

	</React.Fragment>

}

export const Login = ( { setAction, onInput, onSubmit, onError, input={}, ...props } ) => {

	// Grab the theme from redux
	const theme = useSelector( state => ( state.settings?.theme || {} ) )
	const { name, email, password } = input

	return <React.Fragment>
		

		{ /* User data */ }
		<ContainerView style={ { flexGrow: 1, paddingVertical: 40, paddingHorizontal: 20, backgroundColor: theme.colors?.background } }>

			<Image Source={ Branding } style={ { alignSelf: 'flex-end', marginBottom: -15, width: 150 } } />
			<Text style={ { ...theme.text?.title } }>Inloggen</Text>
			<Text style={ { ...theme.text.p, paddingVertical: 20 } }>Voor bestaande gebruikers</Text>


			<Input nativeID="login-email" onError={ onError } validate="^\S+@\S+\.\S+$" error="Vul aub een geldig email adres in" value={ email } onChangeText={ t => onInput( 'email', t ) } autoCompleteType='email' label="E-mail" placeholder="bv m.huizen@gmail.com" />
			<Input nativeID="login-password" onError={ onError } validate=".{8,100}" error="Minimale wachtwoord lengte is 8" value={ password } onChangeText={ t => onInput( 'password', t ) } autoCompleteType='password' label="Wachtwoord" placeholder="*********" secureTextEntry={ true } />

			<View style={ { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20 } }>
				<Button  nativeID="login-submit" onPress={ onSubmit } label='Inloggen' />
				<Text style={ { ...theme.text?.sub, color: theme.colors?.label?.neutral, fontWeight: 'bold' } } onPress={ f => setAction( 'forgotpassword' ) }>Wachtwoord vergeten?</Text>
			</View>

		</ContainerView>

		{ /* Account creation suggest */ }
		<TabCard style={ { flexGrow: 1 } }>
			<Text style={ { ...theme.text?.title, paddingHorizontal: 20, } }>Ben je hier voor het eerst?</Text>
			<Text style={ { ...theme.text.p, paddingVertical: 20, paddingHorizontal: 20 } }>Maak dan je hardlopen voor bedrijven account met de code die je van jouw werkgever hebt ontvangen.</Text>
			<Button nativeID="login-toggleregister" style={ { marginLeft: 20 } } onPress={ f => setAction( 'register' ) } labelStyle={ { textTransform: 'none' } } label='Maak een account aan' />
		</TabCard>

	</React.Fragment>

}

export const Forgot = ( { setAction, onInput, onSubmit, onError, input={}, ...props } ) => {

	// Grab the theme from redux
	const theme = useSelector( state => ( state.settings?.theme || {} ) )
	const { email } = input

	return <React.Fragment>
		

		{ /* User data */ }
		<ContainerView style={ { flexGrow: 1, paddingVertical: 50, paddingHorizontal: 20, backgroundColor: theme.colors?.background } }>

			<Text style={ { ...theme.text?.title } }>Wachtwoord vergeten</Text>
			<Text style={ { ...theme.text.p, paddingVertical: 20 } }>Weet je, je wachtwoord niet meer? Vul hieronder je e-mailadres in. We sturen dan binnen enkele minuten een e-mail waarmee een nieuw wachtwoord kan worden aangemaakt.</Text>


			<Input onError={ onError } validate="^\S+@\S+\.\S+$" error="Vul aub een geldig email adres in" value={ email } onChangeText={ t => onInput( 'email', t ) } autoCompleteType='email' label="E-mail" placeholder="bv m.huizen@gmail.com" />

			<View style={ { flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between' } }>
				<Button onPress={ onSubmit } label='Wachtwoord herstellen' />
				<Text style={ { ...theme.text?.sub, color: theme.colors?.label?.neutral, paddingVertical: 20 } } onPress={ f => setAction( 'login' ) }>Terug naar inloggen</Text>
			</View>
		</ContainerView>	

	</React.Fragment>

}

export const OpenYourEmail = ( { setAction, ...props } ) => {

	// Grab the theme from redux
	const theme = useSelector( state => ( state.settings?.theme || {} ) )

	return <React.Fragment>
		

		{ /* User data */ }
		<ContainerView style={ { flexGrow: 1, paddingVertical: 50, paddingHorizontal: 20, backgroundColor: theme.colors?.background } }>

			<Text style={ { ...theme.text?.title } }>We hebben je gemaild</Text>
			<Text style={ { ...theme.text.p, paddingVertical: 20 } }>We hebben je een link gestuurd waarmee je jouw wachtwoord kan herstellen.</Text>

			<View style={ { flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' } }>
				<Button labelStyle={ { textTransform: 'none' } } onPress={ f => setAction( 'login' ) } label='Naar inloggen' />
			</View>
		</ContainerView>
		
	</React.Fragment>

}