import { log } from '../helpers'
import { dataFromSnap } from './helpers'
import { useSelector } from 'react-redux'

export const listenWorkouts = async ( app, dispatch, action ) => {

	const { db } = app

	return db.collection( 'backendData' ).where( 'type', '==', 'workouts' ).onSnapshot( async docs => {

		const workouts = dataFromSnap( docs )

		// Log query listens
		app.log( `listenWorkouts, read, ${ workouts.length }` )


		return dispatch( action( workouts ) )

	} )

}

export const addWorkoutListenerIfNeeded = async ( app, schemaId, dispatch, action ) => {

	const { db } = app

	log( `Adding listener for schema ${ schemaId }, all schema listeners: `, app.listeners?.workouts )

	// If listener exists, do nothing
	if( app.listeners?.workouts && app.listeners?.workouts[ schemaId ] ) return

	// Old query from when the goals and schemas were dynamically linked: .where( 'schemas', 'array-contains', schemaId )

	app.listeners.workouts[ schemaId ] = db.collection( 'backendData' ).where( 'type', '==', 'workouts' ).where( 'workout-schema.value', '==', schemaId ).onSnapshot( async docs => {

		const workouts = dataFromSnap( docs )

		// Log query listens
		app.log( `addWorkoutListenerIfNeeded, read, ${ workouts.length }` )


		return dispatch( action( workouts ) )

	} )

}

export const listenSchemas = async ( app, dispatch, action ) => {

	const { db } = app

	return db.collection( 'backendData' ).where( 'type', '==', 'schema' ).onSnapshot( async docs => {

		const schemas = dataFromSnap( docs )

		// Log query listens
		app.log( `listenSchemas, read, ${ schemas.length }` )


		return dispatch( action( schemas ) )

	} )

}

export const registerForSchema = async ( app, id ) => {

	const { db, auth, FieldValue } = app
	log( `Registering user ${ auth.currentUser.uid } for schema ${ id }` )
	app.log( `registerForSchema, write, 1` )
	return db.collection( 'users' ).doc( auth.currentUser.uid ).set( { enrolledSchemas: FieldValue.arrayUnion( id ) }, { merge: true } )


}

export const unregisterForSchema = async ( app, id, idsOfWorkouts=[] ) => {

	const { db, auth, FieldValue } = app

	try {

		await db.collection( 'users' ).doc( auth.currentUser.uid ).set( {
			enrolledSchemas: FieldValue.arrayRemove( id ),
			...( idsOfWorkouts?.length > 0 && { completedWorkouts: FieldValue.arrayRemove( ...idsOfWorkouts ) } )
		}, { merge: true } )

		app.log( `unregisterForSchema, write, 1` )

	} catch( e ) {
		log( 'unregisterForSchema error: ', e )
	}


}

export const completeWorkout = async ( app, id ) => {

	const { db, auth, FieldValue } = app

	app.log( `completeWorkout, write, 1` )

	return db.collection( 'users' ).doc( auth.currentUser.uid ).set( { completedWorkouts: FieldValue.arrayUnion( id ) }, { merge: true } )


}