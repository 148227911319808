import React, { useEffect, useState } from 'react'
import { isWeb } from '../../../modules/apis/platform'
import { log } from '../../../modules/helpers'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'

import { View, Text } from './generic'

// Keys
import { GOOGLE_MAPS } from 'react-native-dotenv'

// Load google maps based on expo web experimental instructions, abandoned.
// const loadGoogleMapsIfWeb = apikey => {

// 	// Id to track if head element exists
// 	const id = 'googleMapsScript'

// 	// Only on web
// 	if ( !isWeb ) return
// 	if( document.getElementById( id ) ) return

// 	// Inject style
// 	const script = document.createElement( 'script') 
// 	script.src = `https://maps.googleapis.com/maps/api/js?key=${ apikey }`
// 	script.type = `text/javascript`
// 	script.async = true
// 	script.defer = true
// 	script.id = id

// 	return document.head.append( script )



// }

export const NavigationMap = ( { style, location, ...props } ) => {

	// ///////////////////////////////
	// Init
	// ///////////////////////////////

	// Defaults and state
	const defaultCenter = { lat: -3.745, lng: -38.523 }
	const [ coords, setCoords ] = useState( defaultCenter )

	// Load maps external js
	const { isLoaded } = useJsApiLoader( {
		id: 'google-map-script',
		googleMapsApiKey: GOOGLE_MAPS
	} )

	// Get coordinated for address, only rerun if location prop changed
	useEffect( f => {

		// Find coordinates if they exist and set them to state
		if( !location ) return
		fetch( `https://maps.googleapis.com/maps/api/geocode/json?address=${ encodeURIComponent( location ) }&key=${ GOOGLE_MAPS }` )
		.then( res => res.json() )
		.then( ( { results } ) => results[0] )
		.then( firstmatch => {
			if( firstmatch?.geometry?.location ) setCoords( firstmatch.geometry.location )
		} )

	}, [ location ] )

	// ///////////////////////////////
	// Functionality
	// ///////////////////////////////
	const openNavigation = f => location ? window.open( `https://www.google.com/maps/dir/?api=1&destination=${ encodeURIComponent( location ) }` ) : undefined

	// ///////////////////////////////
	// Visual
	// ///////////////////////////////

	// Map js not loaded, show placeholder
	if( !isLoaded || !GOOGLE_MAPS ) return <View style={ { ...style, backgroundColor: 'gray' } }>
		<Text>This is a placeholder</Text>
	</View>

	return <View style={ { ...style } }>
		<GoogleMap
			mapContainerStyle={ { height: '100%', width: '100%' } }
			center={ coords }
			zoom={ 15 }
			options={ {
				disableDefaultUI: true
			} }
		>
			<Marker 
				position={ coords }
				onClick={ openNavigation }
			/>
		</GoogleMap>
	</View>
	
}