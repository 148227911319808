import { log } from '../helpers'
import { dataFromSnap } from './helpers'

export const listenToLeaderboardIfNeeded = async ( app, dispatch, action ) => {

	log( `${ app.listeners?.leaderboard ? 'Not creating' : 'Creating' } leaderboard listener` )

	const { db, auth } = app
	const { currentUser } = auth

	// If leaderboard already on, return
	if( app.listeners?.leaderboard ) return

	// Get user meta
	if( !currentUser ) return
	const me = await db.collection( 'users' ).doc( currentUser.uid ).get().then( dataFromSnap ) || {}
	const { companyCode } = me
	if( !companyCode ) return


	// If now, create the listener
	app.listeners.leaderboard = db.collection( 'users' ).where( 'companyCode', '==', companyCode ).orderBy( 'score', 'desc' ).limit(10).onSnapshot( async docs => {

		const leaders = dataFromSnap( docs )

		// Log query listens
		app.log( `listenToLeaderboardIfNeeded, read, ${ leaders.length }` )


		return dispatch( action( leaders ) )

	} )

}