import { PURGE } from 'redux-persist'

export default ( state=null, action ) => {

	const { type, payload } = action

	switch( type ) {

		case "SETRUNNINGSESSIONS":

			if( !payload.length ) return { ...state, byWeek: {}, raw: [] }

			return {

				...state,

				// Generate an object that lists sessions per week
				byWeek: payload.reduce( ( acc, session ) => {
					const { year, week } = session.sortingMeta
					
					let thisYear = { ...( acc[year] || {} ) }
					thisYear  = { ...thisYear, [week]: [ ...(thisYear[week] || [] ), session ] }

					return { ...acc, [year]: { ...thisYear } }
				}, {} ),

				// Include raw data as well
				raw: payload
			}

		case "APPENDRAWRUNNINGSESSIONS":

			if( !payload.length ) return { ...state }

			return {

				...state,

				// Include raw data as well
				raw: [ ...( state.raw || [] ), ...payload ]
			}

		case "SETRUNNINGSESSIONMETA":

			// If updated meta is not in raw, something is weird
			if( !state.raw.find( ( { id } ) => payload.id ) ) return

			return {

				...state,

				// Generate an object that lists sessions per week
				byWeek: state.raw.reduce( ( acc, session ) => {
					const { year, week } = session.sortingMeta
					
					let thisYear = { ...( acc[year] || {} ) }

					// Create the by week object, but add the meta only if current session
					if( session.id == payload.id ) { thisYear  = { ...thisYear, [week]: [ ...(thisYear[week] || [] ), { ...session, ...payload } ] } }
					// Default if not current session
					else { thisYear  = { ...thisYear, [week]: [ ...(thisYear[week] || [] ), session ] } }

					return { ...acc, [year]: { ...thisYear } }


				}, {} ),

				// Include raw data as well
				raw: state.raw.map( session => {
					if( session.id == payload.id ) return { ...session, ...payload }
					else return { ...session }
				} )
			}

		case "SETMYRUNNINGSESSIONS":

			const { sessions, iAmTrainer } = payload

			// Keep the sessions in state that are not in this new batch
			// const dedupedOld = state?.mySessions?.filter( ( { id } ) => !payload.find( newSession => newSession.id == id ) ) || []
			const keepOtherType = state?.mySessions?.filter( session => session.iAmTrainer != iAmTrainer ) || []
			return { ...state, mySessions: [ ...sessions, ...keepOtherType ] }

		case PURGE:
			return null

		// Just return the state if no known action is specified
		default:
			return state
	}
}