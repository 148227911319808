export const setWorkouts = workouts => ( {
	type: 'SETWORKOUTS',
	payload: workouts
} )

export const addWorkouts = workouts => ( {
	type: 'ADDWORKOUTS',
	payload: workouts
} )

export const another = true