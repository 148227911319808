import React, { useState, useEffect } from 'react'
import { Pressable,  Image as NativeImage } from 'react-native'

// Data 
import { useSelector } from 'react-redux'
import { weeknumber, currentYear, weeksInYear, log, timestampToHuman, timestampToTime, safeSort } from '../../../modules/helpers'
import { width, widthListener } from '../../../modules/apis/platform'
import { useHistory } from '../../../routes/router'
import app from '../../../modules/firebase/app'

// Visual
import { Text, View, TabCard, Image, Button, Card, HeroHeader, PersonCard } from '../common/generic'
import { NavigationMap } from '../common/map'
import { TopThreeLeaderboard } from '../../hook/leaderboard'

// Assets
//  - Images
import GroupRunning from '../../../../assets/images/HardlopenvoorBedrijven-25.jpg'
import TrainerTalkingToGroup from '../../../../assets/images/HardlopenvoorBedrijven-28.jpg'
import TrainerLookingAtGroupRunning from '../../../../assets/images/HardlopenvoorBedrijven-13.jpg'
import GroupThumb from '../../../../assets/images/HardlopenvoorBedrijven-02-thumb.jpg'
import OneManRunning from '../../../../assets/man-running-alone.jpg'

// - Graphics
import ArrowLeft from '../../../../assets/arrow-left.svg'
import ArrowRight from '../../../../assets/arrow-right.svg'
import AddBall from '../../../../assets/add-ball.svg'
import AddBallWhite from '../../../../assets/add-ball-white.svg'
import Calendar from '../../../../assets/calendar.svg'
import Account from '../../../../assets/account.svg'
import BlueUpsideDownTab from '../../../../assets/blue-upside-down-tab.svg'

// Style
import { container as containerStyle } from '../../../modules/visual/style' 

export const HomeScreen = ( { user={}, unRegisterForSession, registerForSession, viewSession, ...props } ) => {

	const theme = useSelector( state => ( state.settings?.theme || {} ) )
	const { mySessions=[], raw=[] } = useSelector( state => ( state.runniningsessions || {} ) )
	const { enrolledSchemas=[] } = useSelector( state => ( state.user || {} ) )
	const history = useHistory()

	// Filter only future sessions
	const filteredSessions = [ ...( mySessions || [] ) ].filter( session => {
		const sessionTimestamp = new Date( session[ 'groepssessie-date' ]?.value ).getTime()
		// Keep only sessions in futur if not a trainer, for reference they can see past sessions
		if( sessionTimestamp < Date.now() ) return false
		return true
	} )

	// Sort my sessions based on date
	const sortByDate = ( one, two ) => {
		const oneRaw = raw.find( ( { id } ) => id == one.id )
		const twoRaw = raw.find( ( { id } ) => id == two.id )
		return safeSort( oneRaw?.sortingMeta?.date - twoRaw?.sortingMeta?.date )
	}
	mySessions.sort( sortByDate )

	return <View>

		<HeroHeader image={ GroupRunning } />

		{ /*
			////////////////////
			// Group sessions
			////////////////////
		*/ }
		<TabCard style={ { marginBottom: mySessions?.length == 0 ? 0 : -30 } } title="Jouw groepstrainingen" >

			{ /* View known sessions */ }
			{ filteredSessions?.map( ( { id } ) => <SessionCard key={ id } registerForSession={ registerForSession } unRegisterForSession={ unRegisterForSession } sessionIdToShow={ id } viewSession={ viewSession } isMine={ true } /> ) }

			{ /* If we have sessions, show the subtle add button */ }
			{ !!filteredSessions?.length && <Pressable onPress={ f => history.push( '/sessions/book' ) } style={ { flexDirection: 'row', alignSelf: 'flex-end', paddingHorizontal: 20, zIndex: 9, position: 'relative' } }>
				<Text style={ { ...theme.text?.bold, fontSize: 12, paddingRight: 10, paddingVertical: 10, textTransform: 'uppercase' } }>Bekijk meer</Text>
				<Image Source={ AddBall } />
			</Pressable> }

			{ /* Group no group trainings in this week */ }
			{ filteredSessions?.length == 0 && <Card style={ { marginHorizontal: 20, zIndex: 1 } }>
				<Text style={ { ...theme.text?.sub, textTransform: 'uppercase', color: theme.colors?.labels?.subtle, fontSize: 12, marginBottom: 10 } } >Jouw groepstrainingen</Text>
				{ !user?.isTrainer && <Text style={ { ...theme.text?.p, fontSize: 18, lineHeight: 25 } }>Je hebt nog geen groepstrainingen</Text> }
				{ user?.isTrainer && <Text style={ { ...theme.text?.p, fontSize: 18, lineHeight: 25 } }>Er zijn nog geen groepstrainingen</Text> }
				<Button onPress={ f => history.push( '/sessions/book' ) } style={ { marginTop: 20, borderRadius: 20, paddingVertical: 5 } } label={ user?.isTrainer ? `Inzien deelnemers groepstraining` : `Aanmelden groepstraining` } labelStyle={ { fontSize: 12, textTransform: 'uppercase' } } />
			</Card> }

		</TabCard>

		{ /*
			////////////////////
			// Workouts
			////////////////////
		*/ }
		<TabCard style={ { marginTop: 0 } } isBlue={ true } title={ user?.isTrainer ? `Beschikbare schema's` : `Jouw schema${ enrolledSchemas?.length > 1 ? "'s" : '' }` } >
			
			{ /* No schemas active */ }
			{ enrolledSchemas?.length == 0 && <Card style={ { margin: 20, marginTop: 0, shadowOpacity: 0 } }>
				<Text style={ { ...theme.text?.sub, textTransform: 'uppercase', color: theme.colors?.labels?.subtle, fontSize: 12, marginBottom: 10 } } >Jouw volgende training</Text>
				<Text style={ { ...theme.text?.p, fontSize: 18, lineHeight: 25 } }>Je hebt nog geen hardloopschema geselecteerd</Text>
				<Button onPress={ f => history.push( `/schemas/all` ) } style={ { marginTop: 20, borderRadius: 20, paddingVertical: 5 } } label={ `bekijk de schema's` } labelStyle={ { fontSize: 12, textTransform: 'uppercase' } } />
			</Card> }

			{ /* Schemas active */ }
			{ enrolledSchemas?.length > 0 && enrolledSchemas.map( ( id, index ) => <SchemaCard index={ index } key={ id } schemaId={ id } /> ) }

			{ enrolledSchemas?.length > 0 && <Pressable nativeID='home-schemas-view-all' onPress={ f => history.push( '/schemas/all' ) } style={ { flexDirection: 'row', alignSelf: 'flex-end', paddingHorizontal: 20, marginBottom: 20 } }>
				<Text style={ { ...theme.text?.bold, fontSize: 12, paddingRight: 10, textTransform: 'uppercase' } }>Bekijk meer</Text>
				<Image Source={ AddBall } />
			</Pressable> }

		</TabCard>

		{ /* 
			// ///////////////////////////////
			// Leaderboard
			// ///////////////////////////////
		 */ }
		<TabCard title="Leaderboard" >
			<TopThreeLeaderboard style={ { marginTop: 0 } } />
			<Pressable onPress={ f => history.push( '/leaderboard' ) } style={ { flexDirection: 'row', alignSelf: 'flex-end', paddingHorizontal: 20, zIndex: 9, position: 'relative' } }>
				<Text style={ { ...theme.text?.bold, fontSize: 12, paddingRight: 10, paddingVertical: 10, textTransform: 'uppercase' } }>Bekijk volledige leaderboard</Text>
				<Image Source={ AddBall } />
			</Pressable>
		</TabCard>

	</View>
}

export const AvailableSessions = ( { user={}, week, year, registerForSession, viewSession, unRegisterForSession, ...props } ) => {

	const theme = useSelector( state => ( state.settings?.theme || {} ) )
	const { byWeek } = useSelector( state => ( state.runniningsessions || {} ) )
	const history = useHistory()

	// Image cutoff management
	const [ currentWidth, setWidth ] = useState( width() )
	useEffect( f => widthListener( setWidth ) )

	// If no week or year in URL
	if( !week ) {
		history.push( `/sessions/book/${weeknumber()}/${currentYear()}` )
		return <View />
	}

	// If no week or year in URL
	if( !year ) {
		history.push( `/sessions/book/${week}/${currentYear()}` )
		return <View />
	}

	// Filtered data
	const sessionsThisWeek = [ ...( byWeek && byWeek[year] && byWeek[year][week] || [] ) ].filter( session => {
		const sessionTimestamp = new Date( session[ 'groepssessie-date' ]?.value ).getTime()
		// Keep only sessions in futur if not a trainer, for reference they can see past sessions
		if( sessionTimestamp < Date.now() && !user?.isTrainer ) return false
		return true
	} )

	log( 'Sessions this week: ', sessionsThisWeek )
	
	const safelySetWeek = increment => {
		const newWeek = +( week ) + increment
		if( newWeek < 1 ) return history.push( `/sessions/book/${ weeksInYear( parseInt(year)-1) }/${parseInt(year)-1}` )
		if( newWeek > weeksInYear(year) ) return history.push( `/sessions/book/1/${parseInt(year)+1}` )
		return history.push( `/sessions/book/${newWeek}/${year}` )
	}

	return <View>

		<HeroHeader image={ currentWidth < 720 ? TrainerTalkingToGroup : TrainerTalkingToGroup } onBack={ f => history.push( '/sessions/view' ) } />

		<TabCard style={ {  } } title="Groepstrainingen">

			{ /* Week toggle bar */ }
			<View style={ { flexDirection: 'row', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between', width: 550, height: 30, maxWidth: '90%', paddingHorizontal: 10, backgroundColor: theme.colors?.divider, marginVertical: 20, borderRadius: 10 } }>
				<Pressable nativeID="week-arrow-previous" style={ { flex: 1, height: '100%', alignItems: 'flex-start', justifyContent: 'center' } } onPress={ f => safelySetWeek( -1 ) }>
					<Image Source={ ArrowLeft } />
				</Pressable>
				<Text style={ { flexGrow: 1, textAlign: 'center', ...theme.text?.bold, fontSize: 12, textTransform: 'uppercase' } }>Week { week } - { year } { week == weeknumber() ? ' (deze week)' : '' }</Text>
				<Pressable nativeID="week-arrow-next" style={ { flex: 1, height: '100%', alignItems: 'flex-end', justifyContent: 'center' } } onPress={ f => safelySetWeek( 1 ) }>
					<Image Source={ ArrowRight } />
				</Pressable>
			</View>

			{ /* If there are sessions available */ }
			{ sessionsThisWeek?.map( ( { id } ) => <SessionCard key={ id } registerForSession={ registerForSession } unRegisterForSession={ unRegisterForSession } sessionIdToShow={ id } viewSession={ viewSession } /> ) }

			{ /* Group no group trainings in this week */ }
			{ !sessionsThisWeek?.length && <Card style={ { margin: 20, marginTop: 0 } }>
				<Text style={ { ...theme.text?.sub, textTransform: 'uppercase', color: theme.colors?.labels?.subtle, fontSize: 12, marginBottom: 10 } } >Agenda leeg</Text>
				<Text style={ { ...theme.text?.p, fontSize: 18, lineHeight: 25 } }>Er zijn geen geplande trainingen deze week</Text>
			</Card> }
		</TabCard>

	</View>
}

export const DetailedSession = ( { goHome, session, viewPerson, unRegisterForSession, registerForSession } ) => {

	// Redux data
	const theme = useSelector( state => ( state.settings?.theme || {} ) )
	const user = useSelector( state => ( state.user || {} ) )
	let { trainer, company } = session || {}
	const { mySessions } = useSelector( state => ( state.runniningsessions || {} ) )
	const amRegistered = session && !!mySessions.find( ( { id } ) => id == session.id )
	const iAmTheTrainer = user.uid == ( session && session[ 'groepssessie-trainer' ] && session[ 'groepssessie-trainer' ].value )
	const history = useHistory()

	// Set trainer data if I am the trainer and the session has no data on me
	if( iAmTheTrainer && !trainer ) trainer = {
		'trainer-name': { value: user?.name },
		'trainer-avatar': { value: { url: user?.avatar?.uri } },
		'trainer-title': { value: 'Trainer' }
	}

	// Error checking
	if( !company || !trainer ) {
		log( 'Session with missing data: ', session )
	}

	// internals
	const metaCardHeight = 65
	const [ people, setPeople ] = useState( [] )

	// Load the details of the participants, but use the cache of the parent component
	useEffect( f => {

		const getPeople = async f => {
			try {

				if( !session?.participants?.length ) return
				const peopleData = await Promise.all( session?.participants?.map( uid => viewPerson( uid ) ) )
				setPeople( peopleData )

			} catch( e ) {
				log( 'useEffect error: ', e )
			}
		}

		getPeople()

	}, [ session?.participants ] )

	if( !session ) {
		log( 'DetailedSession called without session data' )
		return null
	}

	return <View>

		{ /* Pretty image */ }

		<HeroHeader image={ TrainerLookingAtGroupRunning } onBack={ f => history.goBack() } hasTab={ false } />

		{ /* Session details */ }
		<View style={ { padding: 20, ...containerStyle } } >
			
			<Text style={ { ...theme.text?.title, fontSize: 20 } }>Groepstraining { company && company[ 'company-name' ]?.value }</Text>

			{ /* Meta cards */ }
			<View style={ { marginTop: 20 } } >

				{ /* Date card */ }
				<View style={ { flexDirection: 'row', height: metaCardHeight } } >

					{ /* Calendar icon */ }
					<View style={ { backgroundColor: theme.colors?.primary, borderRadius: 6, height: '100%', padding: 15, marginRight: 20 } }>
						<Image style={ { height: metaCardHeight - 30, width: metaCardHeight - 30 } } Source={ Calendar } />
					</View>

					{ /* Date info */ }
					<View style={ { flexDirection: 'column', justifyContent: 'center' } } >
						<Text style={ { ...theme.text?.sub, textTransform: 'uppercase', color: theme.colors?.labels?.subtle, fontSize: 12, } }>{ timestampToHuman( session[ 'groepssessie-date' ]?.value ) }</Text>
						<Text style={ { ...theme.text?.p, fontSize: 18, lineHeight: 25 } }>{ timestampToTime( session[ 'groepssessie-date' ]?.value ) } - { timestampToTime( session[ 'groepssessie-date' ]?.value, session[ 'groepssessie-duration' ]?.value ) }</Text>
					</View>

					{ /* Useless end bar */ }
					{/* <View style={ { backgroundColor: theme.colors.primary, width: 20, marginLeft: 'auto', borderBottomRightRadius: 6, borderTopRightRadius: 6 } } /> */}
					
				</View>

				{ /* Trainer card */ }
				<View style={ { flexDirection: 'row', height: metaCardHeight, marginTop: 10 } } >

					{ /* Trainer avatar */ }
					<View style={ { backgroundColor: theme.colors?.primary, borderRadius: 6, height: '100%', padding: 8, marginRight: 20 } }>
						<View style={ { borderRadius: '50%', borderWidth: 2, borderColor: 'white' } }>
							<Image style={ { height: metaCardHeight - 20, width: metaCardHeight - 20, borderRadius: '50%' } } Source={ trainer && trainer[ 'trainer-avatar' ]?.value?.url || Account } />
						</View>
					</View>

					{ /* Trainer info */ }
					<View style={ { flexDirection: 'column', justifyContent: 'center' } } >

						<Text style={ { ...theme.text?.p, fontSize: 18, lineHeight: 25 } }>{ trainer && trainer[ 'trainer-name' ]?.value || "Onbekende trainer" }</Text>
						<Text style={ { ...theme.text?.sub, textTransform: 'uppercase', color: theme.colors?.labels?.subtle, fontSize: 12, } }>{ trainer && trainer[ 'trainer-title' ]?.value || "Onbekend niveau" }</Text>
						
					</View>

					{ /* Useless end bar */ }
					{/* <View style={ { backgroundColor: theme.colors.primary, width: 20, marginLeft: 'auto', borderBottomRightRadius: 6, borderTopRightRadius: 6 } } /> */}
					
				</View>

				{ /* Location card */ }
				<View style={ { flexDirection: 'row', marginTop: 10 } } >

					<View style={ { flexDirection: 'column', justifyContent: 'center', borderBottomWidth: 4, borderLeftWidth: 1, borderRightWidth: 1, borderRadius: 4, borderColor: theme?.colors?.divider, borderTopColor: theme?.colors?.primary, borderTopWidth: 4, width: '100%', marginTop: 10 } } >

						{ /* Blue tab */ }
						<View style={ { flexDirection: 'column', zIndex: 2 } }>
							<Image style={ { alignSelf: 'flex-start', marginTop: -4, marginLeft: -1, height: 32, marginBottom: -32 } } Source={ BlueUpsideDownTab } />
							<Text style={ { ...theme.text?.p, fontSize: 12, color: 'white', textTransform: 'uppercase', position: 'absolute', paddingLeft: 10 } }>Locatie</Text>
						</View>

						{ /* Location map */ }
						<NavigationMap location={ session[ 'groepssessie-address' ]?.value } style={ { height: 300, zIndex: 1 } } />

						{ /* Location details */ }
						<View style={ { backgroundColor: 'white', marginTop: -25, zIndex: 2, width: '100%' } }>
							<Text style={ { ...theme.text?.p, fontSize: 18, lineHeight: 25, paddingHorizontal: 10, paddingTop: 10 } }>{ session[ 'groepssessie-address-label' ]?.value }</Text>
							<Text style={ { ...theme.text?.sub, textTransform: 'uppercase', color: theme.colors?.labels?.subtle, fontSize: 12, paddingHorizontal: 10, paddingBottom: 10 } }>{ session[ 'groepssessie-address' ]?.value }</Text>
						</View>		
					</View>

				</View>


			</View>

			{ /* Participants */ }
			<View style={ { flexDirection: 'column', marginTop: 40 } }>
				
				<View style={ { flexDirection: 'row', marginBottom: 20 } }>
					<Text style={ { ...theme?.text?.title, fontSize: 20 } }>Aanmeldingen</Text>
					<Image style={ { marginLeft: 'auto', marginRight: 10 } } Source={ Account } />
					<Text style={ { ...theme?.text?.title, fontSize: 20 } }>{ session?.participants?.length || '0' } / { session[ 'groepssessie-maximum-capacity' ]?.value }</Text>
				</View>

				{ /* List the people */ }
				{ people?.map( person => <PersonCard key={ person.id } name={ person.name } avatar={ person.avatar?.url } isMe={ person.id == user.uid } /> )}

				{ /* Registration button */ }
				{ !iAmTheTrainer && ( amRegistered || ( ( session?.participants?.length || 0 ) < session[ 'groepssessie-maximum-capacity' ]?.value ) ) && <Pressable onPress={ f => amRegistered ? unRegisterForSession( session.id ) : registerForSession( session.id ) } style={ {
					backgroundColor: amRegistered ? theme.colors?.labels?.neutral : theme.colors?.labels?.success,
					flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: 10,
					borderBottomRightRadius: 10, borderBottomLeftRadius: 10, borderTopLeftRadius: 4, borderTopRightRadius: 4, marginTop: 20
				} }>
					<Text style={ { ...theme.text?.bold, color: 'white' } }>{ amRegistered ? 'Afmelden' : 'Aanmelden' } voor deze training</Text>
					{ !amRegistered && <Image style={ { height: 13, width: 13, marginLeft: 10 } } Source={ AddBallWhite } /> }
				</Pressable> }

			</View>

		</View>
		
	</View>

}

const SessionCard = ( { unRegisterForSession, registerForSession, sessionIdToShow, viewSession, isMine } ) => {


	const { mySessions, raw } = useSelector( state => ( state.runniningsessions || {} ) )
	const myUid = useSelector( state => ( state?.user?.uid ) )
	const [ session, setSession ] = useState( raw?.find( ( { id } ) => id == sessionIdToShow ) )
	const amRegistered = isMine !== undefined ? isMine : !!mySessions?.find( ( { id } ) => id == sessionIdToShow )
	const iAmTheTrainer = myUid == ( session && session[ 'groepssessie-trainer' ] && session[ 'groepssessie-trainer' ].value )
	const theme = useSelector( state => ( state.settings?.theme || {} ) )

	useEffect( f => {

		if( !session ) app.loadSpecificGroupSession( sessionIdToShow ).then( f => {
			log( 'Loaded session: ', f )
			setSession( f )
		} )

	}, [ sessionIdToShow ] )

	if( !session ) {
		log( 'SessionCard called without session data' )
		return null
	}

	return <Card key={ session.id } style={ { margin: 20, marginTop: 0, paddingVertical: 0, paddingHorizontal: 0, flexDirection: 'row' } }>
		<View style={ { flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', flex: 1, paddingHorizontal: 20, paddingVertical: 15 } }>
			<Text style={ { ...theme.text?.bold, fontSize: 18, textTransform: 'uppercase', lineHeight: 28 } }>{ timestampToHuman( session[ 'groepssessie-date' ]?.value ) }</Text>
			<Text style={ { ...theme.text?.p, fontSize: 18, lineHeight: 25 } }>{ timestampToTime( session[ 'groepssessie-date' ]?.value ) } - { timestampToTime( session[ 'groepssessie-date' ]?.value, session[ 'groepssessie-duration' ]?.value ) }</Text>
			<Text style={ {
				...theme.text?.sub, textTransform: 'uppercase',
				color: theme.colors?.labels?.subtle,
				fontSize: 12,
				marginBottom: 10
			} } >{ !iAmTheTrainer ? 'Trainer - ' : '' }{ session?.trainer[ 'trainer-name' ]?.value }{ iAmTheTrainer ? ', jij bent de trainer!' : '' }</Text>
			
			{ /* Session still has slots OR I am already registered */ }
			{ !iAmTheTrainer && ( amRegistered || ( ( session?.participants?.length || 0 ) < session[ 'groepssessie-maximum-capacity' ]?.value ) ) && <Button
				onPress={ f => amRegistered ? unRegisterForSession( session.id ) : registerForSession( session.id ) }
				noChevron={ true }
				labelStyle={ { color: amRegistered ? 'white' : theme?.colors?.labels?.success, textAlign: 'center', fontSize: 12 } }
				style={ {
					backgroundColor: amRegistered ? theme?.colors?.labels?.success : 'none',
					borderRadius: 20, paddingVertical: 5, borderColor: theme?.colors?.labels?.success, borderBottomColor: theme?.colors?.labels?.success, borderWidth: 2
				} }
				label={ amRegistered ? `Afmelden` : `Aanmelden` }
			/> }

			{ /* Session is full and I am not registered */ }
			{ !amRegistered && ( ( session?.participants?.length || 0 ) >= session[ 'groepssessie-maximum-capacity' ]?.value ) && <Button
				noChevron={ true }
				labelStyle={ { color: 'white', textAlign: 'center', fontSize: 12 } }
				style={ {
					backgroundColor: theme?.colors?.labels?.success,
					marginTop: 10, borderRadius: 20, paddingVertical: 5, borderColor: theme?.colors?.labels?.success, borderBottomColor: theme?.colors?.labels?.success, borderWidth: 2
				} }
				label={ `Volgeboekt` }
			/> }

		</View>

		{ /* Bekijken card */ }
		<View style={ { height: '100%', marginLeft: 'auto' } }>
			<Image style={ { height: '100%', width: '150px', borderTopRightRadius: 10, borderBottomRightRadius: 10 } } Source={ GroupThumb } /> 
			<Button onPress={ f => viewSession( session.id ) } labelStyle={ { fontSize: 12 } } style={ { borderRadius: 20, paddingVertical: 5, position: 'absolute', bottom: 20, alignSelf: 'center', ...( iAmTheTrainer && { backgroundColor: theme?.colors?.labels?.error } ) } } label={ `Bekijken` } />
		</View>
		
	</Card>

}

export const SchemaCard = ( { schemaId, index } ) => {


	const { raw=[] } = useSelector( state => ( state.schemas || {} ) )
	const { enrolledSchemas=[] } = useSelector( state => ( state.user || {} ) )
	const schema = raw?.find( ( { id } ) => id == schemaId )
	const amRegistered = !!enrolledSchemas?.find( ( { id } ) => id == schemaId )
	const theme = useSelector( state => ( state.settings?.theme || {} ) )
	const history = useHistory()

	if( !schema ) return null

	return <Card nativeID={ `schema-card-${ index }` } key={ schema.id } style={ { margin: 20, marginTop: 0, paddingVertical: 0, paddingHorizontal: 0, flexDirection: 'row' } }>
		<View style={ { flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', flex: 1, paddingHorizontal: 20, paddingVertical: 20 } }>
			<Text style={ { ...theme.text?.schemaTitle } }>{ schema[ 'schema-title' ]?.value }</Text>
			
			<Button
				nativeID={ `schema-card-next-training-${ index }` }
				noChevron={ true }
				labelStyle={ { color: theme.colors?.labels?.success, textAlign: 'center', fontSize: 12 } }
				style={ {
					backgroundColor: 'none',
					marginTop: 10, borderRadius: 20, paddingVertical: 5, paddingHorizontal: 10,
					borderColor: theme?.colors?.labels?.success,
					borderBottomColor: theme?.colors?.labels?.success,
					borderWidth: 2
				} }
				label={ `Volgende training` }
				onPress={ f => history.push( `/schemas/workouts/${ schema.id }` ) }
			/>

		</View>

		{ /* Bekijken card */ }
		<View style={ { height: '100%', marginLeft: 'auto', borderTopRightRadius: 5, borderBottomRightRadius: 5, overflow: 'hidden' } }>
			<Image style={ { height: '100%', width: 'auto' } } Source={ OneManRunning } />
			<Button nativeID={ `schema-card-view-details-${ index }` } onPress={ f => history.push( `/schemas/single/${ schema.id }` ) } labelStyle={ { fontSize: 12 } } style={ { borderRadius: 20, paddingVertical: 5, position: 'absolute', bottom: 20, alignSelf: 'center' } } label={ `Bekijken` } />
		</View>
		
	</Card>

}