import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Text, Pressable, View, Image, Input, Button } from '../common/generic'
import app from '../../../modules/firebase/app'
import { useHistory } from '../../../routes/router'

// Style
import { container as containerStyle } from '../../../modules/visual/style'
import AvatarPlaceholder from '../../../../assets/account.svg'


export const SettingsPanel = ( { user={}, remoteUser={}, go, onInput, onError, saveProfile, ...props } ) => {

	const theme = useSelector( state => ( state.settings?.theme || {} ) )
	const history = useHistory()

	// Account deletion
	const [ showDelete, toggleDelete ] = useState( false )
	const [ oldPassword, setOldPassword ] = useState()
	const [ deleting, setDeleting ] = useState()
	const deleteAccount = async f => {

		// Make sure user is sure
		if( !confirm( 'Account verwijderen? Dit kan niet ongedaan worden gemaakt!' ) ) return
		
		try {

			setDeleting( true )
			await app.deleteUser( oldPassword )
			history.push( '/' )

		} catch( e ) {
			alert( e )
			setDeleting( false )
			toggleDelete( false )
		}

	}

	// Special admin options
	const [ adminCode, setAdminCode ] = useState( '' )
	const [ loadingAdmin, setLoadingAdmin ] = useState( false )
	const makeMeTrainer = async f => {

		// Make sure user is sure
		if( !confirm( 'Wil je je inschrijven als trainer? Doe dit niet als je geen uitnodiging hebt ontvangen.' ) ) return
		
		try {

			if( !user?.name ) throw 'Naam mist uit gegevens'
			setLoadingAdmin( true )
			await app.makeMeTrainer( user )
			setAdminCode( '' )

		} catch( e ) {
			alert( e )
		} finally {
			setLoadingAdmin( false )
		}

	}

	return <View style={ { padding: 20, ...containerStyle } }>
		<View style={ { flexDirection: 'row', justifyContent: 'space-between' } }>
			<Text style={ { ...theme.text.title, marginBottom: 20 } } >Profiel</Text>
			{ <Pressable onPress={ f => go( '/onboard/photo/single' ) }>
				{ user?.avatar && <Image style={ { width: 60, height: 60, borderRadius: '50%', borderWidth: 4, borderColor: 'white' } } Source={ user?.avatar?.uri } /> }
				{ !user?.avatar && <Image style={ { width: 60, height: 60, borderRadius: '50%', borderWidth: 4, borderColor: 'white' } } Source={ AvatarPlaceholder } /> }
			</Pressable> }
		</View>
		<Input nativeID='settings-changename' onError={ onError } validate=".{2,200}" error="Minimaal 2 karakters, maximaal 200 karakters" onChangeText={ t => onInput( 'name', t ) } autoCompleteType='name' placeholder="bv Marc Huizen" value={ user?.name } label='Naam' />
		<Input nativeID='settings-changeemail' onError={ onError } validate="^\S+@\S+\.\S+$" error="Vul aub een geldig email adres in" onChangeText={ t => onInput( 'email', t ) } autoCompleteType='email' placeholder="bv m.huizen@gmail.com" value={ user?.email } label='E-mail' />
		<Input nativeID='settings-newpassword' onError={ onError } validate=".{8,100}" error="Minimale wachtwoord lengte is 8" onChangeText={ t => onInput( 'newpassword', t ) } autoCompleteType='password' placeholder="*********" secureTextEntry={ true } value={ user?.newpassword } label='Nieuw wachtwoord' />
		{ ( user?.newpassword || ( user?.email != remoteUser?.email ) ) && <Input nativeID='settings-currentpassword' onError={ onError } validate=".{8,100}" error="Minimale wachtwoord lengte is 8" onChangeText={ t => onInput( 'currentpassword', t ) } autoCompleteType='password' placeholder="*********" secureTextEntry={ true } value={ user?.currentpassword } label='Oude wachtwoord' /> }
		<Button onPress={ saveProfile } label='Wijzigingen opslaan' />
		<Text nativeID='settings-triggerdelete' style={ { opacity: .5, marginTop: 10 } } onPress={ f => toggleDelete( !showDelete ) }>Account verwijderen</Text>

		{ showDelete && <View style={ { paddingVertical: 20 } }>
			
			<Input nativeID='settings-currentpasswordfordelete' onError={ onError } validate=".{8,100}" error="Minimale wachtwoord lengte is 8" onChangeText={ setOldPassword } autoCompleteType='password' placeholder="*********" secureTextEntry={ true } value={ oldPassword } label='Huidige wachtwoord' />
			<Button nativeID='settings-confirmdelete' onPress={ deleteAccount } style={ { backgroundColor: 'red' } } label={ deleting ? 'Verwijderen...' : 'Permanent account verwijderen' } />

		</View> }

		{ !user.isTrainer && <View>

			<Text style={ { ...theme.text.title, marginBottom: 20, marginTop: 40 } }>Doelen</Text>
			{ /* <Input label='Ik wil werken aan' value={ user?.goals?.focuslist?.join( ', ' ) } /> */ }
			<Input label='Hoe vaak per week' value={ user?.goals?.frequency } />
			<Input label='Afstand (km)' value={ user?.goals?.distance } />
			<Button onPress={ f => go( '/onboard/frequency' ) } label='Doelen aanpassen' />

			<Text style={ { ...theme.text.title, marginBottom: 20, marginTop: 80 } }>Geavanceerde opties</Text>
			<Input label='Admin code'  onChangeText={ setAdminCode } value={ adminCode } />

			{ adminCode == 'ikbeneentrainer' && <Button onPress={ loadingAdmin ? f => f : makeMeTrainer } label={ loadingAdmin ? 'Opslaan...' : 'Maak mij een trainer' } /> }

		</View> }


	</View>
}

export const another = true