import React from 'react'

// Visual
import { Component, Container, Loading, Main } from '../../stateless/common/generic'
import Navigation from '../common/navigation'
import { AllSchemas, SingleSchema, Workouts, Workout } from '../../stateless/profile/schemas-screen'

// Data
import { log, catcher } from '../../../modules/helpers'
import app from '../../../modules/firebase/app'

// Redux
import { connect } from 'react-redux'

class Schemas extends Component {

	state = {
		loading: false
	}

	render() {

		const { loading } = this.state
		const { view, entryId } = this.props.match?.params

		if( loading ) return <Loading message={ loading } />

		return <Container>
			<Navigation title='' />
			<Main.Top>
				{ view == 'all' && <AllSchemas /> }
				{ view == 'single' && <SingleSchema schemaIdToShow={ entryId } /> }
				{ view == 'workouts' && <Workouts schemaIdToShow={ entryId } /> }
				{ view == 'singleworkout' && <Workout workoutIdToShow={ entryId } /> }
			</Main.Top>
		</Container>

	}

}

export default Schemas
// export default connect( store => ( {
// 	user: store.user
// } ) )( Thing )