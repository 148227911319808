import React from 'react'
import { Animated, Platform } from 'react-native'
import { WhitePortal } from 'react-native-portal'
import { Component } from '../../stateless/common/generic'
import { Header } from '../../stateless/common/navigation'
import { connect } from 'react-redux'
import { withRouter } from '../../../routes/router'
import { toggleDarkMode } from '../../../redux/actions/settingsActions'
import { capitalize, log, Dialogue } from '../../../modules/helpers'
import { isWeb, isIos, isAndroid, version, OS } from '../../../modules/apis/platform'
import app from '../../../modules/firebase/app'

class Navigation extends Component {

	state = {
		drawer: false,
		drawerWidth: 300,
		drawerSpeed: 500
	}

	// Drawer toggler
	toggleDrawer = async config => {

		const { drawer, drawerWidth, drawerSpeed } = this.state
		const force = config == 'force'

		// If the drawer is open, move it to closed position
		if( drawer && !force ) Animated.timing( this.pan, { toValue: { x: drawerWidth, y: 0 }, duration: drawerSpeed, useNativeDriver: false } ).start( f => this.updateState( { drawer: !drawer } ) )
		
		// If drawer is closed, dirst mount, then animate open
		else if( !drawer || force ) {
			if( !force ) await this.updateState( { drawer: !drawer } )
			Animated.timing( this.pan, { toValue: { x: 0, y: 0 }, duration: drawerSpeed, useNativeDriver: false } ).start()
		}
	}

	// The animated .event function returns a function
	pan = new Animated.ValueXY( { x: this.state.drawerWidth, y: 0 }, { useNativeDriver: false } )
	handleDrag = Animated.event(
		[ { translationX: this.pan.x } ],
		// { useNativeDriver: Platform.OS != 'web' },
		{ useNativeDriver: false }
	)

	panDrawer = ( { nativeEvent } ) => {

		const { translationX, velocityX, state, oldState } = nativeEvent
		const { drawerWidth } = this.state

		// If drag is not on x axis or is to the left of base position ignore
		if( !translationX || translationX < 0 ) return

		// Animate if state is pulling
		if( state == 4 ) return this.handleDrag( nativeEvent )

		// If we let go, either reset or hide
		if( state == 5 && ( translationX > drawerWidth / 5 || velocityX > 2 ) ) return this.toggleDrawer()
		else return this.toggleDrawer( 'force' )

	}

	toggleDarkMode = f => this.props.dispatch( toggleDarkMode() )

	render( ) {

		const { title, user, history } = this.props
		const { drawer, drawerWidth, drawerOffset } = this.state

		const links = [ ]

		// Add links relevant to the user
		if( user ) links.push(
				{ label: 'Home', to: `/` },
				{ label: 'Mijn gegevens', to: `/settings` },
				{ label: 'Groepstrainingen', to: '/sessions/book' },
				{ label: 'Hardloopschema', to: '/schemas/all' },
				{ label: 'Leaderboard', to: '/leaderboard' },
				{ label: 'Uitloggen', onPress: app.logout }
		)


		return <React.Fragment>

			<Header
				drawerTranslate={ { transform: this.pan.getTranslateTransform() } }
				drawerOffset={ drawerOffset }
				drawerWidth={ drawerWidth }
				pan={ this.panDrawer }
				toggle={ this.toggleDrawer } 
				title={ capitalize( title ) }
				drawer={ drawer }
				toggleDark={ this.toggleDarkMode }
				go={ to => history.push( to ) }
				links={ links }
			/>

			<WhitePortal name="menu" />
			
		</React.Fragment>
	}

}

export default withRouter( connect( store => ( {
	user: !!store.user
} ) )( Navigation ) )