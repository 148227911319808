import { applyMiddleware, combineReducers, createStore } from 'redux'
import promise from 'redux-promise-middleware'
import logger from 'redux-logger'
import { dev } from '../modules/apis/platform'

// Redux persistance
import { persistStore, persistReducer } from 'redux-persist'
import storage from './storage'

// Reducers
import settingsReducer from './reducers/settingsReducer'
import userReducer from './reducers/userReducer'
import runningSessionsReducer from './reducers/runningSessionsReducer'
import schemasReducer from './reducers/schemasReducer'
import workoutsReducer from './reducers/workoutsReducer'
import leaderboardReducer from './reducers/leaderboardReducer'
const reducers = combineReducers( { 
	settings: settingsReducer,
	user: userReducer,
	runniningsessions: runningSessionsReducer,
	schemas: schemasReducer,
	workouts: workoutsReducer,
	leaderboard: leaderboardReducer
} )

// Root reducer
const metaReducer = ( state, action ) => {

	switch( action.type ) {
		
		case "RESETAPP":
			console.log( 'Resetting app storage' )
			return reducers( undefined, action )

	}

	return reducers( state, action )
}

// Persisted reducer
const persistedReducer = persistReducer( { key: 'root', storage: storage }, metaReducer )

// Middleware
const middleware = dev ? applyMiddleware( logger, promise ) : applyMiddleware( promise )


// Export store and persistor
export const store = createStore( persistedReducer, middleware )
export const persistor = persistStore( store )
export const purge = async f => {

	console.log( 'Persistor purge started' )
	await persistor.pause()
	await persistor.flush()
	await persistor.purge()
	console.log( 'Persistor purge succeeded' )

}
