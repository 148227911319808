import React, { useState, useEffect } from 'react'

// Router
import { useHistory } from '../../../routes/router'

// Visual
import { Text as NativeText, ScrollView, TextInput, View as NativeView, SafeAreaView, KeyboardAvoidingView, Image as NativeImage, Pressable as NativePressable } from 'react-native'
import { Link as NativeLink, withRouter } from '../../../routes/router'
import { isWeb, isIos, isCI } from '../../../modules/apis/platform'
import NativeSlider from '@react-native-community/slider'

// Helpers
import { log } from '../../../modules/helpers'

// Redux
import { useSelector } from 'react-redux'

// Assets
import Chevron from '../../../../assets/chevron.svg'
import Tab from '../../../../assets/tab-stretched.svg'
import TabBlue from '../../../../assets/tab-blue-stretched.svg'
import TabReverse from '../../../../assets/tab-reverse.svg'
import ArrowLeft from '../../../../assets/arrow-left.svg'
import CheckIconGreen from '../../../../assets/check-icon-green.svg'
import DismissCross from '../../../../assets/dismiss-cross.svg'

import { container as containerStyle } from '../../../modules/visual/style' 


// Optimised react root component
export class Component extends React.Component {

  constructor( props ) {
    super( props )

    // Class-wide functions
    this.promiseState = newState => new Promise( resolve => this.setState( newState, resolve ) )
    this.updateState = updates => this.promiseState( { ...this.state, ...updates } )

  }

}

// ///////////////////////////////
// Generalised defaults
// ///////////////////////////////
export const Text = ( { style, ...props } ) => <NativeText style={ { maxWidth: '100%', ...style } } { ...props } />
export const View = ( { style, ...props } ) => <NativeView style={ { flexWrap: 'wrap', maxWidth: '100%', boxSizing: 'border-box', ...style } } { ...props } />
export const Pressable = ( { style, ...props } ) => <NativePressable style={ { flexWrap: 'wrap', maxWidth: '100%', ...style } } { ...props } />
export const Image = ( { Source, style,  ...props } ) => isWeb ? <img style={ { ...style } } src={ Source } { ...props } /> : <Source style={ style } { ...props } />

// ///////////////////////////////
// Container
// ///////////////////////////////

export const ContainerView = ( { children, style, containerStyle, ...props } ) => {

	return <View style={ style } { ...props }>
		<View style={ { 
			width: '100%', 
			maxWidth: '600px', 
			marginLeft: 'auto', 
			marginRight: 'auto',
			...containerStyle
		} }>
			{ children }
		</View>
	</View>

}


// ///////////////////////////////
// Input types
// ///////////////////////////////
export const Input = ( { label, labelStyle, style, value, validate, error, onError, ...props } ) => {

	// Grab the theme from redux
	const theme = useSelector( state => ( state.settings?.theme || {} ) )

	// Validation and subsequent error showing
	const [ showError, setErrorState ] = useState( undefined )

	// Validation after render, including cleanup on rerender
	useEffect( f => {


		// Timer tracker
		let timer = undefined

		// Set error with a delay of half a second
		const setErrorWithDelay = e => {
			timer = setTimeout( f => {
				setErrorState( e )
				onError( e )
			}, 1000 )
		}

		// If there is a value and a validation string, do validation
		if( value && validate ) {

			// Do a regex match of the validator against the input
			const [ fullmatch ] = value?.match( new RegExp( validate ) ) || []
			log( value, validate, fullmatch )

			// If the value doesn't match the regex and no error has been set, set the error
			if( value != fullmatch && !showError ) setErrorWithDelay( error )

			// If there is a valid value, but there is an error set, unset it
			if( value == fullmatch && showError ) {
				setErrorState( undefined )
				onError( undefined )
			}
		}

		// Return cleanup function
		return f => clearTimeout( timer )

	} )

	return <View>
		{ label && <Text style={ { ...labelStyle, ...theme.text?.label, marginBottom: 10 } }>{ label }</Text> }
		<TextInput value={ value } style={ { color: theme.colors?.text, background: 'white', padding: 10, marginBottom: 15, borderRadius: theme.roundness, borderBottomColor: theme.colors.primary, borderBottomWidth: 2, ...style } } placeholderTextColor={ theme.colors.placeholder } { ...props } />
		{ showError && <Text style={ { ...theme.text?.sub, opacity: .5, marginBottom: 15, color: theme.colors?.labels?.error } }>{ showError }</Text> }
	</View>

}

export const Button = ( { label, labelStyle, noChevron=false, style, ...props } ) => {

	const theme = useSelector( state => ( state.settings?.theme || {} ) )
	const chevStyle = { height: 14, width: 8, marginLeft: 10 }
	const buttonStyle = { backgroundColor: theme.colors?.primary, paddingHorizontal: 20, paddingVertical: 10,  borderRadius: theme.roundness, borderBottomColor: 'rgba(0, 0, 0, 0.25)', borderBottomWidth: 2 }

	return <Pressable style={ { alignSelf: 'flex-start', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', ...buttonStyle, ...style } } { ...props } >
		<Text style={ { flex: 1, color: 'white', ...theme.text?.label, fontFamily: theme.font?.bold, ...labelStyle } }>{ label || 'submit' }</Text>
		{ !noChevron && ( isWeb ? <NativeImage style={ chevStyle } source={ Chevron } /> : <Chevron style={ chevStyle } width={ chevStyle.width } height={ chevStyle.height } /> ) }
	</Pressable>

}

export const Slider = ( { ...props } ) => {

	const theme = useSelector( state => ( state.settings?.theme || {} ) )

	return <NativeSlider { ...props } />
}

// ///////////////////////////////
// Screens
// ///////////////////////////////

// Loading screen
export const Loading = ( { message } ) => <Container style={ { justifyContent: 'center' } }>
		<Text style={ { textAlign: 'center', marginTop: 20 } }>{ message || 'Loading' }</Text>
</Container>

export const HeadingText = ({ children, style }) => {
	const theme = useSelector( state => ( state.settings?.theme || {} ) )
	return <Text style={ { ...theme.text?.title, fontSize: 20, zIndex: 10, ...style } }>{ children }</Text>
}

export const TabHeading = ({ children, style }) => <HeadingText style={{ paddingHorizontal: 20, marginBottom: 20, ...style }}>{ children }</HeadingText>

// Tab flap
export const TabCard = ( { children, style, tabStyle, title, tags, pullUp=true, isBlue=false, isRight=false, ...props } ) => {

	const theme = useSelector( state => ( state.settings?.theme || {} ) )
	const useTabStyle = { 
		width: 2000,
		height: 50,
		zindex: 1,
		resizeMode: 'cover',
		position: 'absolute',
		right: isRight ? 'auto' : 0,
		left: isRight ? 0 : 'auto',
		...tabStyle
	}

	return <View style={ { zIndex: 1, marginTop: 5 - useTabStyle.height, overflow: 'visible', ...style } } { ...props }>
		<ContainerView containerStyle={ { height: useTabStyle.height, position: 'relative' } }>
			<View style={ { alignSelf: isRight ? 'flex-end' : 'flex-start', width: 'auto', marginLeft: isRight ? 'auto': 0, minWidth: '50%' } } >
				{ !isBlue && ( isWeb ? <NativeImage style={ useTabStyle } source={ Tab } /> : <Tab style={ useTabStyle } width={ useTabStyle.width } height={ useTabStyle.height } /> ) }
				{ isBlue && ( isWeb ? <NativeImage style={ useTabStyle } source={ TabBlue } /> : <TabBlue style={ useTabStyle } width={ useTabStyle.width } height={ useTabStyle.height } /> ) }
				{ title  && <HeadingText style={{ paddingLeft: isRight ? 30 : 20, paddingRight: isRight ? 20 : 30, paddingTop: 25 } }>{ title }</HeadingText> }
				{ tags  && <View style={ { paddingLeft: isRight ? 30 : 20, paddingRight: isRight ? 20 : 30, fontSize: 20, zIndex: 10, paddingTop: 25, flexDirection: 'row' } }>
					{ tags?.map( tag => <Button
						key={ tag }
						noChevron={ true }
						labelStyle={ { color: theme?.colors?.sub, textTransform: 'uppercase', textAlign: 'center', fontSize: 12 } }
						style={ { backgroundColor: theme?.colors?.background, borderRadius: 20, paddingVertical: 5, borderBottomWidth: 0, marginRight: 10 } }
						label={ tag }
					/> ) }
				</View> }
			</View>
		</ContainerView>
		<ContainerView style={ { backgroundColor: isBlue ? theme.colors?.background : undefined } } containerStyle={ { paddingVertical: 40, ...style } }>
			{ children }
		</ContainerView>
	</View>

}

export const HeroHeader = ( { style, image, imageStyle, onBack, hasTab = true, ...props } ) => {

	const theme = useSelector( state => ( state.settings?.theme || {} ) )

	const useImageStyle = {
		width: '100%', 
		height: '100%',
		position: 'relative', 
		zIndex: 1,
		overflow: 'hidden',
		objectFit: 'cover',
		...imageStyle
	}

	return <View style={ { width: '100%', height: '50vh', maxHeight: '90vw' } }>
		{ onBack && 
		<Pressable style={ { backgroundColor: theme.colors?.surface, position: 'absolute', bottom: (hasTab ? 60 : 10), zIndex: 2, height: 24, width: 85, margin: 10, flexDirection: 'row', borderRadius: 12, alignItems: 'center', justifyContent: 'center' } } onPress={ onBack }>
			<Image style={ { paddingRight: 10 } } Source={ ArrowLeft } />
			<Text style={ { ...theme.text?.bold } }>Terug</Text>
		</Pressable> }
		<View style={ useImageStyle } { ...props }>
			<NativeImage source={ image } style={ { width: '100%', height: '100%' } } />
		</View>
	</View>

}

export const PersonCard = ({ style, name, avatar, isMe = false }) => {

	const theme = useSelector( state => ( state.settings?.theme || {} ) )

	const entryStyle = {
		backgroundColor: 'white',
		borderBottomWidth: 4, borderWidth: 1, borderRadius: 10,
		borderColor: isMe ? theme?.colors?.labels?.success : theme?.colors?.divider,
		padding: 10, marginTop: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'
	}

	return <View style={{ ...entryStyle, ...style }}>
		<Avatar avatar={ avatar } name={ name } />
		<Text style={ { ...theme?.text?.label } } >{ name || 'Anoniem' }</Text>
		{ isMe && <Image style={ { marginLeft: 'auto' } } Source={ CheckIconGreen } /> }
	</View>

}

export const Avatar = ({style, avatar, name='', size = 40 }) => {

	const avatarStyle = {
		width: size,
		height: size,
		borderRadius: '50%',
		backgroundColor: '#EBEFF0', 
		color: 'white', 
		textAlign: 'center', 
		textTransform: 'uppercase', 
		fontSize: size / 2,
		boxSizing: 'border-box'
	}

	return avatar ? <Image style={{ ...avatarStyle, marginRight: 20, ...style }} Source={ avatar } /> : <Text style={ { ...avatarStyle, marginRight: 20, padding: size/5, ...style } }>{ name[0] || 'a' }</Text> 

}
export const TabCardRight = ( { children, style, ...props } ) => {

	const theme = useSelector( state => ( state.settings?.theme || {} ) )
	const tabStyle = { width: 168, height: 32, zindex: 1, alignSelf: 'flex-end' }


	return <View style={ { marginTop: 0 - tabStyle.height, overflow: 'visible' } } { ...props }>
		{ isWeb ? <NativeImage style={ tabStyle } source={ TabReverse } /> : <TabReverse style={ tabStyle } width={ tabStyle.width } height={ tabStyle.height } /> }
		<View style={ style } >
			{ children }
		</View>
	</View>

}

export const Card = ( { children, style, ...props } ) => {

	const theme = useSelector( state => ( state.settings?.theme || {} ) )
	const cardStyle = { padding: 20, borderWidth: 1, borderBottomWidth: 5, borderStyle: 'solid', borderColor: '#EBEFF0', borderRadius: 10 }


	return <View style={ { backgroundColor: theme.colors?.surface, ...cardStyle, ...style } } >
		{ children }
	</View>
}

const modalPadding = 30
export const Modal = ( { visible, onDismiss=f=>alert( 'Dismiss function undefined' ), children, ...props } ) => visible ? <Pressable onPress={ onDismiss } style={ { position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba( 0, 0, 0, .5 )', zIndex: 9 } }>
	<View style={ { width: 400,  maxWidth: '90%', backgroundColor: 'white', paddingHorizontal: modalPadding, paddingVertical: modalPadding * 1.5, borderRadius: 5 } }>
		<Pressable onPress={ onDismiss } style={ { position: 'absolute', top: modalPadding, right: modalPadding } }>
			<Image style={ { height: 20, width: 20 } } Source={ DismissCross } />
		</Pressable>
		{ children }
	</View>
</Pressable> : null

// ///////////////////////////////
// Positioning
// ///////////////////////////////
const sharedStyles = { paddingHorizontal: 0, paddingVertical: 0, maxWidth: '100%', flexGrow: 1, flexShrink: 0 }
export const Main = {
	Center: ( { children, style } ) => ( <ScrollView style={ { width: '100%' } } showsHorizontalScrollIndicator={ false } showsVerticalScrollIndicator={ false } contentContainerStyle={ { ...sharedStyles, alignItems: 'center', justifyContent: 'center',  ...style } }>
			{ children }
	</ScrollView> ),
	Top: ( { children, style } ) => ( <ScrollView style={ { width: '100%' } } showsHorizontalScrollIndicator={ false } showsVerticalScrollIndicator={ false } contentContainerStyle={ { ...sharedStyles, ...style } }>{ children }</ScrollView> )
}

// General app container
const bgStyles = { position: 'absolute', top: 0, left: 0, bottom: 0, minWidth: '100%', minHeight: '100%' }
export const Container = ( { style, children, theme={}, Background } ) => <KeyboardAvoidingView style={ { flex: 1 } } behavior={ isIos ? 'padding' : 'height' } >

		<SafeAreaView style={ { flex: 1, width: '100%', backgroundColor: theme?.colors?.primary } }>

		<View style={ {
			flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', backgroundColor: theme?.colors?.background, overflow: 'hidden',
			...style
		} }>
			{ Background && ( isWeb ? <NativeImage style={ bgStyles } source={ Background } /> : <Background height={ '101%' } preserveAspectRatio="xMidYMid slice" style={ bgStyles } /> ) }
			{ Background && <View style={ { position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, backgroundColor: theme?.colors?.background } } /> }
			{ children }
		</View>
		
	</SafeAreaView>

</KeyboardAvoidingView>

