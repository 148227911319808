import React from 'react'
import { BackHandler } from 'react-native'

// Helpers
import { log, wait } from '../modules/helpers'
import { isWeb, dev } from '../modules/apis/platform'

// Redux
import { connect } from 'react-redux'
import { reloadTheme } from '../redux/actions/settingsActions'
import { purge } from '../redux/store'

// Firebase
import firebase from '../modules/firebase/app'

// Udates
import { updateIfAvailable } from '../modules/apis/updates'

// ///////////////////////////////
// Components
// ///////////////////////////////

// generic
import { Component, Loading } from '../components/stateless/common/generic'
import { PortalProvider } from 'react-native-portal'

// Onboarding
import LoginAndRegistration from '../components/stateful/onboarding/login-and-registration'
import GuidedOnboard from '../components/stateful/onboarding/guided-onboarding'

// personal 
import Home from '../components/stateful/profile/home-screen'
import Settings from '../components/stateful/profile/settings-screen'
import Schemas from '../components/stateful/profile/schemas-screen'
import { LeaderboardPage } from '../components/hook/leaderboard'

// Routing
import { Switch, Route, withRouter } from './router'


// Route maneger class
class Routes extends Component {

	state = {
		loading: 'De app wordt geladen...'
	}

	componentDidMount = async () => {

		// Handle purge requests
		if( isWeb && typeof location != 'undefined' && location.href.includes( 'purge' ) ) {
			log( 'Purge request detected' )
			await purge()
			await firebase.logout()
			location.href = '/'
		}

		// Make demo data if needed
		if( isWeb && typeof location != 'undefined' && location.href.includes( 'createTestData=true' ) ) {
			log( '🛑 Demo data requested' )
			await this.updateState( { loading: 'Creating demo data' } )
			await firebase.createTestData()
			await this.updateState( { loading: 'Demo data created' } )
			await wait( 2000 )
		}
		if( isWeb && typeof location != 'undefined' && location.href.includes( 'createTestDataWithMeAsTrainer=true' ) ) {
			log( '🛑 Demo data requested' )
			await this.updateState( { loading: 'Creating demo data' } )
			await firebase.createTestDataWithMeAsTrainer()
			await this.updateState( { loading: 'Demo data created' } )
			await wait( 2000 )
		}
		if( isWeb && typeof location != 'undefined' && location.href.includes( 'deleteTestData=true' ) ) {
			log( '🛑 Demo data requested' )
			await this.updateState( { loading: 'Deleting demo data' } )
			await firebase.deleteTestData()
			await this.updateState( { loading: 'Demo data deleted' } )
			await wait( 2000 )
		}

		if( isWeb && typeof location != 'undefined' && location.href.includes( 'deleteAndBackupOrphanWorkouts=true' ) ) {
			log( '🛑 Demo data requested' )
			await this.updateState( { loading: 'Cleaning up orphan workouts' } )
			await firebase.deleteAndBackupOrphanWorkouts()
			await this.updateState( { loading: 'Orphan workouts cleaned' } )
			await wait( 2000 )
		}

		const { history, user, dispatch } = this.props

		// If url is wrongly using hash (for example due to a direct link), fix it
		if( window?.location ) {
			const { href, host } = window.location
			const [ fullMatch, pathMatch ] = href.match( /(\w+)#/ ) || []
			if( pathMatch ) window.history.replaceState( null, '', `/#/${pathMatch}` )
		}


		// Register back button handler
		this.backHandler = BackHandler.addEventListener( 'hardwareBackPress', f => {

			// Navigate back
			history.goBack()

			// Stop the event from bubbling up and closing the app
			return true

		} )

		// Set the state to initialised if a user is already in stor
		this.setState( { init: !!user } )

		// Init firebase
		await firebase.init( history )

		// Reload the theme in case it changed relative to the store
		await dispatch( reloadTheme() )
		
		// Disable loading screen
		return this.setState( { loading: false } )
	}

	shouldComponentUpdate = ( nextProps, nextState ) => {

		const { history, user } = nextProps
		const { pathname } = history.location

		// Update trigger
		this.scheduleUpdateCheck()

		// ///////////////////////////////
		// Redirect rules
		// ///////////////////////////////
		const noRedir = isWeb && typeof location != 'undefined' && location.href.includes( 'noredir' )

		// Not logged in but not on the home page => go to home
		if( !noRedir && !pathname.includes( '/login' ) && !user ) history.push( `/login` )

		// If logged in but at home => go to profile
		if( !noRedir && user && ( pathname == '/login' || pathname == '/' ) ) history.push( '/sessions' )

		// If logged in and no profile info redirect to onboarding
		if( !noRedir && user && ( !user.trainer && !user.goals ) && !pathname.includes( '/onboard' ) ) history.push( '/onboard' )

		// analytics
		if( pathname && !dev ) firebase.analytics.setCurrentScreen( pathname ).catch( e => log( 'analytics error: ', e ) )

		// On prop or state chang, always update
		return true

	}

	// Schedule an update check
	scheduleUpdateCheck = f => {

		if( this.scheduledUpdateCheck ) {
			clearTimeout( this.scheduledUpdateCheck )
			this.scheduledUpdateCheck = undefined
		}

		// Limit to once every 5 seconds in case they are navigating around
		this.scheduledUpdateCheck = setTimeout( f => {
			log( 'Checking for update...' )
			updateIfAvailable()
		}, 5000 )

	}

	render() {

		const { theme } = this.props
		const { loading } = this.state

		{ /* Paper theme provider */ }
		return <PortalProvider>
			{ loading && <Loading message={ loading } /> }
			{ /* App router */ }
			{ !loading && <Switch>

				{ /* Onboarding */ }
				<Route path='/login/:action?/:uid?/:name?/:code?' component={ LoginAndRegistration } />
				<Route path='/onboard/:step?/:single?' component={ GuidedOnboard } />

				{ /* Settings */ }
				<Route path='/settings' component={ Settings } />

				{ /* Schemas */ }
				<Route path='/schemas/:view?/:entryId?' component={ Schemas } />

				{ /* Leaderboard */ }
				<Route path='/leaderboard' component={ LeaderboardPage } />

				{ /* Home */ }
				<Route path='/sessions/:intent?/:selector?/:filter?' component={ Home } />

			</Switch> }

		</PortalProvider>

	}

}

export default withRouter( connect( store => ( {
	user: store?.user,
	theme: store?.settings?.theme
} ) )( Routes ) )