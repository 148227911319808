import { Platform } from 'react-native'
import Constants from 'expo-constants'
const { manifest } = Constants

// Platform management
export const isWeb = Platform.OS == 'web'
export const isIos = Platform.OS == 'ios'
export const isAndroid = Platform.OS == 'android'
export const OS = Platform.OS
export const dev = process.env.NODE_ENV == 'development' || ( isWeb && typeof location !== 'undefined' && location.href.includes( 'debug=true' ) )
export const isCI = typeof jest != 'undefined'

const { version: appVersion, revisionId } = manifest
export const version = revisionId || 'development'

// Screen management
import { Dimensions } from 'react-native'
export const width = f => Dimensions.get( 'window' ).width
export const height = f => Dimensions.get( 'window' ).height
export const widthListener = callback => {
	Dimensions.addEventListener( 'change', ( { window, screen } ) => callback( window.width ) )
	return Dimensions.removeEventListener( 'change', callback )
}

export { Platform } from 'react-native'

