export const setRunningSessions = sessions => ( {
	type: 'SETRUNNINGSESSIONS',
	payload: sessions
} )

export const appendRawRunningSessions = sessions => ( {
	type: 'APPENDRAWRUNNINGSESSIONS',
	payload: sessions
} )

export const setMyRunningSessions = ( sessions, iAmTrainer ) => ( {
	type: 'SETMYRUNNINGSESSIONS',
	payload: {
		sessions: sessions,
		iAmTrainer: iAmTrainer
	}
} )

export const updateRunningSessionMeta = sessionMeta => ( {
	type: 'SETRUNNINGSESSIONMETA',
	payload: sessionMeta
} )