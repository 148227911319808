import { PURGE } from 'redux-persist'

export default ( state=[], action ) => {

	const { type, payload } = action

	switch( type ) {

		case "SETRAWSCHEMAS":
			return { ...state, raw: payload }

		case PURGE:
			return []

		// Just return the state if no known action is specified
		default:
			return state
	}
}