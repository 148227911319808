import { catcher, log } from '../helpers'
import { dataFromSnap } from './helpers'
import { resetApp } from '../../redux/actions/settingsActions'
import { purge } from '../../redux/store'
import { unregisterListeners, registerListeners } from './_listeners'


// ///////////////////////////////
// Listeners
// ///////////////////////////////

// Listen to user authentication
export const listenUserLogin = ( app, dispatch, action, listeners ) => new Promise( resolve => {
	// Listen to the user object
	const authListener = app.auth.onAuthStateChanged( async user => {

		log( `User change: `, user )

		try {

			// Register listeners if we are logged in
			if( user ) {
				registerListeners( app, dispatch, listeners )

				const profile = await getUserProfile( app, user )
				await dispatch( action( profile ) )
				
			}

			// Unregister listeners and reset app if we are not logged in
			if( !user ) {
				unregisterListeners( app.listeners )
				await purge()
				await dispatch( resetApp( ) )
			}

			// Resolve when done
			resolve( authListener )

		} catch( e ) {
			log( 'Auth listener error', e )
			alert( 'Authentication error' )
		} finally {
			app.log( `listenUserLogin, read, 1` )
		}

	} )
} ) 

// Listen to user changes
export const listenUserChanges = ( app, dispatch, action ) => app.db.collection( 'users' ).doc( app.auth.currentUser.uid ).onSnapshot( doc => {

	app.log( `listenUserChanges, read, 1` )

	return dispatch( action( {
		email: app.auth.currentUser.email,
		...dataFromSnap( doc ) 
	} ) )

} )

// ///////////////////////////////
// User actions
// ///////////////////////////////

// Register a new user by email and password
export const registerUser = async ( app, name, email, password, companyCode ) => {

	try {
		// Create account
		// await app.auth.createUserWithEmailAndPassword( email, password )

		// Create account using backend to check company existance
		await app.func.httpsCallable( "createUserWithEmailAndPasswordAndCompanyCode" )( { companyCode: companyCode, email: email, password: password } )
		await app.loginUser( email, password )

		// Update profile to include name, this also triggers redux
		await app.updateUser( {
			name: name,
			companyCode: companyCode
		} )

	} catch( e ) {
		catcher( e )
	}  finally {
		app.log( `registerUser, write, 2` )
	}

}

// Register using the backend function
export const createUserWithEmailAndPasswordAndUid = async ( app, uid, name, email, password ) => {

	try {
		// Create account using admin backend powers
		await app.func.httpsCallable( "createUserWithEmailAndPasswordAndUid" )( { uid: uid, email: email, password: password } )
		await app.loginUser( email, password )

		// Update profile to include name, this also triggers redux
		await app.updateUser( {
			name: name,
			isTrainer: true
		} )

	} catch( e ) {
		catcher( e )
	}  finally {
		app.log( `registerUser, write, 2` )
	}

}

// Log in the user, this will trigger the user object listener
export const loginUser = async ( auth, email, password ) => auth.signInWithEmailAndPassword( email, password )

// Update the user profile and return the new user object to store
export const updateUser = async ( app, userUpdates ) => {

	let { uid, email, newpassword, currentpassword, newavatar, oldavatar, ...updates } = userUpdates
	const { currentUser } = app.auth
	if( !currentUser ) throw 'Not currently logged in'
	
	try {

		// If this is a sensitive change, reauthenticate
		if( currentpassword ) {
			const { EmailAuthProvider } = app.Auth
			await currentUser.reauthenticateWithCredential( EmailAuthProvider.credential( currentUser.email, currentpassword ) )
		}

		// If email change was requested, set to firebase auth object
		if( email && currentpassword ) {
			await currentUser.updateEmail( email )
		}
		if( newpassword && currentpassword ) {
			await currentUser.updatePassword( newpassword )
		}

		// If new file was added
		if( newavatar ) {

			// Upload new file
			const { ref } = await app.storage.child( newavatar.path ).put( newavatar.blob )
			const url = await ref.getDownloadURL()
			updates.avatar = {
				uri: url,
				path: newavatar.path
			}
			// Delete old file
			if( oldavatar ) await app.storage.child( oldavatar.path ).delete().catch( e => log( e ) )
		}

		// Set other properties to store
		await app.db.collection( 'users' ).doc( currentUser.uid ).set( {
			...updates,
			updated: Date.now()
		}, { merge: true } )


	} catch( e ) {
		log( 'updateUser error: ', e )
		throw e
	} finally {
		app.log( `updateUser, write, 1-4` )
	}

}

// Get user profile
export const getUserProfile = async ( app, user ) => {

	const { db } = app

	try {

		const userMeta = await db.collection( 'users' ).doc( user.uid ).get().then( doc => doc.data() ).catch( f => ( { } ) )
		const { companyCode } = userMeta || {}
		const [ company ] = companyCode ? await db.collection( 'backendData' ).where( 'company-code.value', '==', companyCode ).get().then( dataFromSnap ).catch( f => [] ) : []

		return {
			uid: user.uid,
			email: user.email,
			...userMeta,
			companyName: companyCode && company && company[ 'company-name' ]?.value
		}

	} catch( e ) {
		log( 'getUserProfile error: ', e )
	} finally {
		app.log( `getUserProfile, read, 2` )
	}

}

// Recover password
export const resetPassword = ( auth, email ) => auth.sendPasswordResetEmail( email )

// Logout
export const logoutUser = async app => {
	
	const { auth, listeners } = app
	await purge()
	// unregisterListeners( listeners )
	if( auth ) await auth.signOut().catch( log )

}

// Delete
export const deleteUser = async ( app, password ) => {

	const { auth, db } = app
	const { currentUser } = auth
	const { EmailAuthProvider } = app.Auth

	try {

		await currentUser.reauthenticateWithCredential( EmailAuthProvider.credential( currentUser.email, password ) )

		await Promise.allSettled( [
			db.collection( 'settings' ).doc( currentUser.uid ).delete(),
			db.collection( 'users' ).doc( currentUser.uid ).delete(),
			db.collection( 'userMeta' ).doc( currentUser.uid ).delete()
		] ).then( ignore => ignore )

		await auth.currentUser.delete()
		await logoutUser( app )

	} catch( e ) {
		log( 'Deletion error: ', e )
		throw e.message
	} finally {
		app.log( `deleteUser, write, 4` )
	}

} 

// Info from other users

export const getUserDetailsByUid = ( app, uid ) => app.db.collection( 'users' ).doc( uid ).get().then( dataFromSnap ).finally( f => app.log( `getUserDetailsByUid, read, 1` ) )