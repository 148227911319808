import { Alert as NativeAlert, LogBox, Dimensions } from 'react-native'
import { dev, isWeb, isCI } from './apis/platform'
import { v4 as uuidv4 } from 'uuid'
import * as Random from 'expo-random'
import * as Linking from 'expo-linking'

// ///////////////////////////////
// Visual
// ///////////////////////////////
export const Dialogue = ( title, message, options=[ { text: 'ok', onPress: f => Promise.resolve() } ] ) => new Promise( resolve => {

	// Option has text and onpress
	if( !isWeb ) NativeAlert.alert(
		title,
		message,
		options.map( option => ( { ...option, onPress: f => option.onPress && option.onPress().then( res => resolve( res ) ) } ) ),
		{ cancelable: true }
	 )

	if( isWeb ) {
		if( confirm( `${title}\n\n${message}` ) ) options[0].onPress().then( resolve )
		else resolve()
	}

} )

export const wait = ( time, error=false ) => new Promise( ( res, rej ) => setTimeout( error ? rej : res, time ) )

export const capitalize = string => string ? string.charAt(0).toUpperCase() + string.slice(1) : undefined

// ///////////////////////////////
// Debugging
// ///////////////////////////////

export const log = ( ...content ) => {
	if( dev ) console.log( ...content )
}

export const error = ( ...content ) => {
	if( dev ) {
		console.log( ...content )
		console.trace()
	}
}

export const catcher = e => {
	error( e )
	// throw to sentry
	throw e
}

export const ignoreErrors = arr => LogBox && LogBox.ignoreLogs( arr )

// ///////////////////////////////
// Generators
// ///////////////////////////////
export const getuid = async f => uuidv4( { random: await Random.getRandomBytesAsync( 16 ) } )

export const sendEmail = ( to, subject, body ) => Linking.openURL( `mailto:${to}?subject=${subject}&body=${body}` )

// ///////////////////////////////
// Data manipulation
// ///////////////////////////////
export const uniqueByProp = ( array, propToFilterBy ) => {

	const matches = []

	return array.filter( item => {

		const valueThatShouldBeUnique = item[ propToFilterBy ]

		// If already found, exclude
		if( matches.includes( valueThatShouldBeUnique ) ) return false

		// Otherwise register and keep it
		matches.push( valueThatShouldBeUnique )
		return true

	} )

}

// Safari is a bit anal about the .sort spec and only accepts -1, 1 and 0 while chrome accepts -any +any and 0.
export const safeSort = rank => {
	if( rank > 0 ) return 1
	if( rank < 0 ) return -1
	return 0
}


// ///////////////////////////////
// Dates
// ///////////////////////////////

const months = {
	0: 'jan',
	1: 'feb',
	2: 'mrt',
	3: 'apr',
	4: 'mei',
	5: 'jun',
	6: 'jul',
	7: 'aug',
	8: 'sep',
	9: 'okt',
	10: 'nov',
	11: 'dec'
}

const weekdays = {
	0: 'zo',
	1: 'ma',
	2: 'di',
	3: 'wo',
	4: 'do',
	5: 'vr',
	6: 'za'
}

// Baselines
const msInADay = 86400000
const today = new Date()

// profiling the 1st of jan
const oneJan = new Date( today.getFullYear(), 0, 1 )
const oneJanDayType = oneJan.getDay()

export const timestampToHuman = ms => {
	const date = new Date( ms )
	// Dutchify
	return `${weekdays[date.getDay()]} ${date.getDate()} ${months[date.getMonth()]}`
}
// Give timestamp of now, except in CI
export const timestampToTime = ( ms, offsetInMinutes ) => {
	if( isCI ) return '12:11'
	if( !offsetInMinutes ) return new Date( ms || Date.now() ).toString().match( /\d{1,2}:\d{1,2}/ )[0]

	// In the case of an offset
	const date = new Date( ms )
	const timestamp = date.getTime()
	const offsetTimestamp = timestamp + ( offsetInMinutes * 1000 * 60 )
	
	return new Date( offsetTimestamp ).toString().match( /\d{1,2}:\d{1,2}/ )[0]

}

// Weeks are defined by the number of 7 day increments that have elapsed, based on thursday because they are always in week 1
export const weeknumber = date => {

	const dayToGetWeeknumberOf = date ? new Date( date ) : today
	const dayInWeek = (dayToGetWeeknumberOf.getDay() + 6) % 7

	// Set date to this thursday
	var thisThursday = new Date( dayToGetWeeknumberOf )
	thisThursday.setDate(thisThursday.getDate() - dayInWeek + 3)

	// Get first thursday of the year to calculate difference in weeks
	var firstThursday = new Date( dayToGetWeeknumberOf.getFullYear(), 0, 1 )
	if (firstThursday.getDay() !== 4) {
		firstThursday.setMonth(0, 1 + ((4 - firstThursday.getDay()) + 7) % 7)
	}

	// Calculate de difference in weeks
	const weekNumber = Math.ceil((thisThursday.valueOf() - firstThursday.valueOf()) / (msInADay * 7));
    return weekNumber

}

export const currentYear = () => {

	const now = new Date()
	return now.getFullYear()

}

export const weeksInYear = (year) => {

	year = year || currentYear()

	const lastDay = new Date(year, 11, 31, 12, 0, 0, 0);
	return weeknumber( lastDay.getTime() )

}


// Calculating the distance until the next day of a week
export const distanceToNextDayType = ( targetDay, baseline ) => {

	// Find the index of the target day, where sunday is 0 because javascript
	const week = [ 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday' ]
	const targetIndex = week.indexOf( targetDay )

	// If parameter is not a day, break
	if( targetIndex == -1 ) throw 'Faulty day name'

	// Get the index of today relative to the local device
	const dayIndex = baseline ? baseline.getDay() : today.getDay()

	const distance = targetIndex - dayIndex

	// If the distance is negative than the day is in the past and we want next week's day of that type
	if( distance < 0 ) return distance + 7

	// If the distance is positive, the day is in the future and we're good
	return distance

}

export const dateOfNext = day => {

	// Generate midnight today ( the first second of today, whic is technically midnight yesterday )
	const startofToday = new Date( today )
	startofToday.setHours( 0, 0, 0, 0 )
	
	// Next day of the type input into the function, also it's first second of that day
	const nextDayOfSuppliedType = new Date()
	// Set the next day of that typed based on day of the month
	nextDayOfSuppliedType.setDate( startofToday.getDate() + distanceToNextDayType( day ) )
	nextDayOfSuppliedType.setHours( 0, 0, 0, 0 )

	// console.log( nextDayOfSuppliedType )
	return new Date( nextDayOfSuppliedType )
}

export const getInitials = ( name='' ) => {
	const names = name.split(" ")
	if( Array.isArray( names ) ) return `${ names[0] && names[0][0].toUpperCase() }${ names[1] && names[1][0].toUpperCase() }`
	return names[0].toUpperCase()
}

export const getShortName = (name, max=[{width:300,length:6}, {width:340,length:8}, {width:380,length:10}]) => {


	if(!name) return 'hardloper'

	// Sort by width
	max.sort( (a,b) => a.width - b.width )
	const width = Dimensions.get('window').width
	log( 'Width is', width )

	// Return full name if screen width is larger than limits
	if( width > max[ max.length-1 ].width ) return name

	// Get the max character length
	let maxChars = 9999
	max.some( max => {
		maxChars = max.length
		return width <= max.width
	})

	// Return full name if not too long
	if( name.length <= maxChars ) return name

	// Shorten to first name
	let names = name.split(" ")
	if( Array.isArray( names ) ) names = names[0]
	
	// Return shortened name
	return names.length > maxChars ? `${names.substring(0, maxChars)}…` : names;
}