import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from '../../../routes/router'

// Visual
import { Text, View, Input, Button, TabCard, Image, Slider, Pressable, HeroHeader } from '../../stateless/common/generic'
import ImagePicker from '../../stateful/common/image-picker'
import { SchemaCard } from '../profile/home-screen'
import { width, widthListener } from '../../../modules/apis/platform'

// Assets
// - Images
import GroupRunningWithTrainer from '../../../../assets/images/HardlopenvoorBedrijven-03.jpg'
import GroupRunningFromSide from '../../../../assets/images/HardlopenvoorBedrijven-01.jpg'
import GroupRunningFWithTrainerFront from '../../../../assets/images/HardlopenvoorBedrijven-18.jpg'
import GroupRunningAlt from '../../../../assets/images/HardlopenvoorBedrijven-10.jpg'
import GroupRunningWithTrainerAlt from '../../../../assets/images/HardlopenvoorBedrijven-04.jpg'

// - Graphics
import OnboardingLogo from '../../../../assets/onboarding-logo.svg'
import AvatarPlaceholder from '../../../../assets/avatar-placeholder.svg'


// icons
import Tape from '../../../../assets/measuring-tape.svg'
import Stopwatch from '../../../../assets/stopwatch.svg'
import Shoe from '../../../../assets/shoe.svg'
import TapeWhite from '../../../../assets/measuring-tape-white.svg'
import StopwatchWhite from '../../../../assets/stopwatch-white.svg'
import ShoeWhite from '../../../../assets/shoe-white.svg'
import HeartWhite from '../../../../assets/heart-white.svg'
import Heart from '../../../../assets/heart.svg'
import AddBall from '../../../../assets/add-ball.svg'


// ///////////////////////////////
// Header
// ///////////////////////////////
export const OnboardHeading = ( { ...props } ) => {

	const theme = useSelector( state => ( state.settings?.theme || {} ) )
	const byStyles = { ...theme?.text?.small, color: 'white', paddingTop: 5, textTransform: 'uppercase' }

	return <View style={ { flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', height: 44, paddingBottom: 10, paddingTop: 5, paddingHorizontal: 15, backgroundColor: theme?.colors?.accent } }>
		<Image style={ { height: 29 } } Source={ OnboardingLogo } />
		<Text style={ { ...byStyles, paddingLeft: 11 } }>Powered by</Text>
		<Text style={ { ...byStyles, fontWeight: 'bold', paddingLeft: 5 } }>atletiekunie</Text>
	</View>
}

// ///////////////////////////////
// Welcome screen
// ///////////////////////////////
export const Welcome = ( { name='Marc', isTrainer=false, nextStep, ...props } ) => {

	const theme = useSelector( state => ( state.settings?.theme || {} ) )

	// Image cutoff management
	const [ currentWidth, setWidth ] = useState( width() )
	useEffect( f => widthListener( setWidth ) )

	return <View>

		<HeroHeader image={ currentWidth < 720 ? GroupRunningWithTrainer : GroupRunningWithTrainer } />
		
		<TabCard isRight={ true } style={ { paddingHorizontal: 10 } }>
			<Text style={ { ...theme.text?.title,  } }>Welkom { name },</Text>
			{ !isTrainer && <Text style={ { ...theme?.text?.p, paddingVertical: 30 } }>Voordat jij je eerste trainingen kiest en aan de slag gaat, willen we eerst nog een paar dingen van je weten. Op die manier kunnen we jou zo goed mogelijk helpen.</Text> }
			{ isTrainer && <Text style={ { ...theme?.text?.p, paddingVertical: 30 } }>Welkom als trainer bij hardlopen.nl voor bedrijven. In de volgende stap kun je een foto kiezen zodat de deelnemers je makkelijk herkennen in het dashboard.</Text> }
			<Button nativeID='onboard-start' onPress={ nextStep } style={ { alignSelf: 'center' } } label="Doorgaan" />

		</TabCard>

	</View>

}


// ///////////////////////////////
// Avatar photo
// ///////////////////////////////
export const Photo = ( { photo, isTrainer, nextStep, onInput, ...props } ) => {


	const theme = useSelector( state => ( state.settings?.theme || {} ) )

	return <View style={ { flexDirection: 'column', padding: 40 } }>
				
			<Text style={ { ...theme.text?.title } }>Upload een foto</Text>
			{ !isTrainer && <Text style={ { ...theme?.text?.p, paddingTop: 20, paddingBottom: 40 } }>Zo kunnen jouw collega’s en de trainer je makkelijk herkennen in het dashboard.</Text> }
			{ isTrainer && <Text style={ { ...theme?.text?.p, paddingTop: 20, paddingBottom: 40 } }>Zo kunnen de deelnemers je makkelijk herkennen in het dashboard.</Text> }
			<ImagePicker onSelected={ image => onInput( 'photo', image ) } size={ 200 } image={ photo || AvatarPlaceholder }>
				<Text style={ { ...theme?.text?.label, color: theme?.colors?.primary, paddingTop: 20, paddingBottom: 40, textAlign: 'center' } } >Upload foto</Text>
			</ImagePicker>
			<Button onPress={ nextStep } style={ { alignSelf: 'center' } } label={ isTrainer ? "Opslaan" : "Doorgaan" } />

			<Text nativeID='onboard-doavatarlater' onPress={ nextStep } style={ { ...theme.text?.sub, color: theme.colors?.primary, textTransform: 'uppercase', textAlign: 'center', marginTop: 30 } } >Ik doe dit op een later moment</Text>


	</View> 
}

// ///////////////////////////////
// Running frequency
// ///////////////////////////////
export const Frequency = ( { nextStep, frequency, onInput, ...props } ) => {

	const theme = useSelector( state => ( state.settings?.theme || {} ) )

	// Image cutoff management
	const [ currentWidth, setWidth ] = useState( width() )
	useEffect( f => widthListener( setWidth ) )

	return <View style={ { flexDirection: 'column' } }>
		
		<HeroHeader image={ GroupRunningFromSide } />
		
		<TabCard style={{ paddingHorizontal: 20 }} isRight={true}>

			<Text style={ { ...theme?.text?.title, paddingVertical: 30 } }>Hoe vaak wil je – naast de groepstraining – wekelijks hardlopen?</Text>

			{ /* <Slider
				value={ frequency }
				onValueChange={ val => onInput( 'frequency', val ) }
				minimumValue={1}
				maximumValue={4}
				step={ 1 }
				tapToSeek={ true }
				minimumTrackTintColor="rgba(0, 146, 210, 0.3)"
				maximumTrackTintColor="rgba(0, 146, 210, 0.3)"
				thumbTintColor={ theme?.colors?.primary }
			/> */ }

			<View style={ { flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingBottom: 20 } } >
				<DistanceCube inputKey='frequency' nativeID='onboard-setfrequency-0' selectedDistance={ frequency } distance='0' suffix='x' subtitle='De groepstraining is voor mij al een mooie uitdaging' onInput={ onInput } />
				<DistanceCube inputKey='frequency' nativeID='onboard-setfrequency-1' selectedDistance={ frequency } distance='1' suffix='x' subtitle='Dat moet lukken en past in mijn agenda' onInput={ onInput } />
				<DistanceCube inputKey='frequency' nativeID='onboard-setfrequency-2' selectedDistance={ frequency } distance='2' suffix='x' subtitle='Ik ga er helemaal voor' onInput={ onInput } />
				{  /* <DistanceCube inputKey='frequency' nativeID='onboard-setfrequency-none' selectedDistance={ frequency } distance='' subtitle='Weet ik nog niet' onInput={ onInput } /> */ }
			</View>

			{ frequency && <Text style={ { ...theme?.text?.p, paddingVertical: 30, textAlign: 'center' } }>{ frequency } keer per week</Text> }
			<Button nativeID='onboard-setfrequency' onPress={ nextStep } style={ { alignSelf: 'center' } } label="Doorgaan" />

			<Text onPress={ nextStep } style={ { ...theme.text?.sub, color: theme.colors?.primary, textTransform: 'uppercase', textAlign: 'center', marginTop: 30 } } >Ik vul dit op een later moment in</Text>
			

		</TabCard>

	</View>

}

// ///////////////////////////////
// Cube helper
// ///////////////////////////////
const FocusCube = ( { style, title, focuslist, onInput, Icon, IconSelected, ...props } ) => {

	const theme = useSelector( state => ( state.settings?.theme || {} ) )
	const selected = focuslist.includes( title )
	const baseStyle = {
		flexDirection: 'column', width: 120, height: 90, alignItems: 'center', justifyContent: 'space-between', borderWidth: 2, borderBottomWidth: 5, borderRadius: 10, padding: 10, margin: 10,
		backgroundColor: selected ? theme.colors?.primaryLight : 'none',
		borderColor: selected ? theme.colors?.primary : theme.colors?.border,
	}
	const toggle = f => {

		// This function used to be able to add multiple, but the client wanted to limit it
		// let newFocusList = selected ? [ ...focuslist.filter( item => item != title ) ] : [ ...focuslist, title ]

		let newFocusList = [ title ]
		onInput( 'focuslist', newFocusList )
	}

	return <Pressable { ...props } onPress={ toggle } style={ { ...baseStyle, ...style } } >
		<Image style={ { height: 40, width: 40 } } Source={ selected ? IconSelected : Icon } />
		<Text style={ { ...theme.text?.label, fontFamily: theme.font?.bold, textAlign: 'center', textTransform: 'capitalize', color: theme.colors?.text } } >{ title }</Text>
	</Pressable>
}
const DistanceCube = ( { style, inputKey='distance', selectedDistance, distance, suffix='KM', subtitle, onInput, ...props } ) => {

	const theme = useSelector( state => ( state.settings?.theme || {} ) )
	const selected = selectedDistance == distance
	const baseStyle = {
		flexDirection: 'column', flex: 1, minWidth: 120, height: 120, alignItems: 'center', justifyContent: 'center', borderWidth: 2, borderBottomWidth: 5, borderRadius: 10, padding: 10, margin: 10,
		borderColor: selected ? theme.colors?.primary : theme.colors?.border,
		backgroundColor: selected ? theme.colors?.primaryLight : 'none'
	}
	const toggle = f => onInput( inputKey, distance )
	// const selected = focuslist.includes( title )
	// const baseStyle = {
	// 	flexDirection: 'column', width: 120, height: 90, alignItems: 'center', justifyContent: 'space-between', borderWidth: 2, borderBottomWidth: 5, borderRadius: 10, padding: 10, margin: 10,
	// 	backgroundColor: selected ? theme.colors?.primaryLight : 'none',
	// 	borderColor: selected ? theme.colors?.primary : theme.colors?.border,
	// }
	// const toggle = f => {
	// 	let newFocusList = selected ? [ ...focuslist.filter( item => item != title ) ] : [ ...focuslist, title ]
	// 	onInput( 'focuslist', newFocusList )
	// }

	return <Pressable { ...props } onPress={ toggle } style={ { ...baseStyle, ...style } } >
		{ distance && <Text style={ { ...theme.text?.label, fontFamily: theme.font?.bold, textAlign: 'center', textTransform: 'capitalize', color: theme.colors?.text } } >{ distance } { suffix }</Text> }
		{ subtitle && <Text style={ { ...theme.text?.label, fontFamily: theme.font?.regular, fontSize: 12, textAlign: 'center', color: theme.colors?.text } } >{ subtitle }</Text> }
	</Pressable>
}

// ///////////////////////////////
// Goal focus
// ///////////////////////////////
export const Focus = ( { nextStep, focuslist, onInput, ...props } ) => {

	const theme = useSelector( state => ( state.settings?.theme || {} ) )

	// Image cutoff management
	const [ currentWidth, setWidth ] = useState( width() )
	useEffect( f => widthListener( setWidth ) )

	return <View style={ { flexDirection: 'column' } }>

		<HeroHeader image={ GroupRunningAlt } />
		
		<TabCard style={{ paddingHorizontal: 20 }} isRight={true}>

			<Text style={ { ...theme?.text?.title, paddingBottom: 20, textAlign: 'center' } }>Waar wil je aan werken?</Text>

			<View style={ { flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingBottom: 20 } } >
				<FocusCube nativeID='onboard-focus-basis' onInput={ onInput } focuslist={ focuslist } Icon={ Shoe } IconSelected={ ShoeWhite } title='basis' />
				<FocusCube nativeID='onboard-focus-afstand' onInput={ onInput } focuslist={ focuslist } Icon={ Tape } IconSelected={ TapeWhite } title='afstand' />
				<FocusCube nativeID='onboard-focus-snelheid' onInput={ onInput } focuslist={ focuslist } Icon={ Stopwatch } IconSelected={ StopwatchWhite } title='snelheid' />
				<FocusCube nativeID='onboard-focus-conditie' onInput={ onInput } focuslist={ focuslist } Icon={ Heart } IconSelected={ HeartWhite } title='conditie' />
			</View>

			<Button nativeID='onboard-confirmfocus' onPress={ nextStep } style={ { alignSelf: 'center' } } label="Doorgaan" />

			<Text onPress={ nextStep } style={ { ...theme.text?.sub, color: theme.colors?.primary, textTransform: 'uppercase', textAlign: 'center', marginTop: 30 } } >Ik vul dit op een later moment in</Text>

		</TabCard>

	</View>

}

// ///////////////////////////////
// Distance 
// ///////////////////////////////
export const Distance = ( { nextStep, distance, onInput, ...props } ) => {

	const theme = useSelector( state => ( state.settings?.theme || {} ) )

	// Image cutoff management
	const [ currentWidth, setWidth ] = useState( width() )
	useEffect( f => widthListener( setWidth ) )

	return <View style={ { flexDirection: 'column' } }>

		<HeroHeader image={ GroupRunningAlt } />
		
		<TabCard style={{ paddingHorizontal: 20 }} isRight={true}>

			<Text style={ { ...theme?.text?.title, paddingBottom: 20, textAlign: 'center' } }>Hoe ver wil je over 20 weken kunnen hardlopen?</Text>

			<View style={ { flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingBottom: 20 } } >
				<DistanceCube nativeID='onboard-setdistance-3' selectedDistance={ distance } distance='3' subtitle='Lijkt me heerlijk als ik dat kan' onInput={ onInput } />
				<DistanceCube nativeID='onboard-setdistance-5' selectedDistance={ distance } distance='5' subtitle='Mooie afstand voor mij' onInput={ onInput } />
				<DistanceCube nativeID='onboard-setdistance-10' selectedDistance={ distance } distance='10' subtitle='Mooi rond getal' onInput={ onInput } />
				<DistanceCube nativeID='onboard-setdistance-15' selectedDistance={ distance } distance='15' subtitle='Zo kom ik nog eens ergens' onInput={ onInput } />
			</View>

			<Button nativeID='onboard-setdistance-confirm' onPress={ nextStep } style={ { alignSelf: 'center' } } label="Doorgaan" />

			<Text onPress={ nextStep } style={ { ...theme.text?.sub, color: theme.colors?.primary, textTransform: 'uppercase', textAlign: 'center', marginTop: 30 } } >Ik vul dit op een later moment in</Text>

		</TabCard>

	</View>

}

export const CurrentDistance = ( { nextStep, ...props } ) => {

	const theme = useSelector( state => ( state.settings?.theme || {} ) )
	const [ distance, setDistance ] = useState( 0 )

	// Image cutoff management
	const [ currentWidth, setWidth ] = useState( width() )
	useEffect( f => widthListener( setWidth ) )

	return <View style={ { flexDirection: 'column' } }>

		<HeroHeader image={ GroupRunningFWithTrainerFront } />
		
		<TabCard style={{ paddingHorizontal: 20 }} isRight={true}>

			<Text style={ { ...theme?.text?.title, paddingBottom: 20, textAlign: 'center' } }>Hoe ver kun je nu al hardlopen?</Text>

			<View style={ { flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingBottom: 20 } } >
				<DistanceCube nativeID='onboard-setcurrentdistance-0' selectedDistance={ distance } distance='NIET' suffix=' ' subtitle='Dit traject is mijn vuurdoop, dus kom maar op' onInput={ f => setDistance( 'NIET' ) } />
				<DistanceCube nativeID='onboard-setcurrentdistance-3' selectedDistance={ distance } distance='3' subtitle='Het begin is er, toch mooi 1/14 marathon' onInput={ f => setDistance( 3 ) } />
				<DistanceCube nativeID='onboard-setcurrentdistance-5' selectedDistance={ distance } distance='5' subtitle='12,5 rondjes op de atletiekbaan loop ik zonder te stoppen' onInput={ f => setDistance( 5 ) } />
				<DistanceCube nativeID='onboard-setcurrentdistance-10' selectedDistance={ distance } distance='10' subtitle='Ik loop wel vaker en dit is mijn afstand' onInput={ f => setDistance( 10 ) } />
			</View>

			<Button nativeID='onboard-setcurrentdistance-confirm' onPress={ nextStep } style={ { alignSelf: 'center' } } label="Doorgaan" />

			<Text onPress={ nextStep } style={ { ...theme.text?.sub, color: theme.colors?.primary, textTransform: 'uppercase', textAlign: 'center', marginTop: 30 } } >Ik vul dit op een later moment in</Text>

		</TabCard>

	</View>

}

// ///////////////////////////////
// Selected workout
// ///////////////////////////////

export const SelectedSchema = ( { ...props } ) => {

	const theme = useSelector( store => ( store.settings?.theme || {} ) )
	const [ schema ] = useSelector( store => store?.user?.enrolledSchemas || [] )
	const history = useHistory()

	// Image cutoff management
	const [ currentWidth, setWidth ] = useState( width() )
	useEffect( f => widthListener( setWidth ) )

	return <View style={ { flexDirection: 'column' } }>
		
		<HeroHeader image={ GroupRunningWithTrainerAlt } />
		
		<TabCard isRight={true}>

			{ schema ? <React.Fragment>
				<Text style={ { ...theme?.text?.p, paddingHorizontal: 20, paddingTop: 20, paddingBottom: 40 } }>Op basis van de door jou ingevulde gegevens hebben we het onderstaande schema voor je geselecteerd!</Text>

				{ schema && <SchemaCard schemaId={ schema } /> }

				{  /* <Text style={ { ...theme?.text?.p, paddingHorizontal: 20, paddingTop: 20, paddingBottom: 40 } }>Wil je toch vaker per week gaan hardlopen of liever voor een andere afstand trainen, bekijk dan hier alle andere beschikbare schema’s.</Text> */ }

			</React.Fragment> : <Text style={ { ...theme?.text?.p, paddingHorizontal: 20, paddingTop: 20, paddingBottom: 40 } }>Op basis van de door jou ingevulde gegevens hebben we geen passende schemas gevonden. Je kan later zelf een schema selecteren.</Text> }

			<Button nativeID='onboard-selectedschema-confirm' onPress={ f => history.push( '/' ) } style={ { alignSelf: 'center' } } label="Afronden" />

		</TabCard>

	</View>

}