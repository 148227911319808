import React from 'react'

// Visual
import { Component, Container, Loading, Main } from '../../stateless/common/generic'
import { SettingsPanel } from '../../stateless/profile/settings-screen'
import Navigation from '../common/navigation'

// Data
import { log, catcher } from '../../../modules/helpers'
import app from '../../../modules/firebase/app'

// Redux
import { connect } from 'react-redux'

class Home extends Component {

	state = {
		loading: false,
		input: {  }
	}

	onInput = ( key, value ) => {

		const { input } = this.state

		return this.updateState( { input: { ...input, [key]: value } } )

	}

	onError = error => this.updateState( { error: error } )

	saveProfile = async f => {

		try {

			const { input } = this.state
			const { history } = this.props

			// Trimming
			if( input.name ) input.name = input?.name?.trim()
			if( input.email ) input.email = input?.email?.trim()

			// If there was an input error do not continue
			if( input?.error ) throw input.error

			// If sensitive op without password let them know
			if( ( input?.email || input?.newpassword ) && !input?.currentpassword ) throw 'Uw huidige wachtwoord is nodig voor deze aanpassing'

			// Start saving to remote
			await this.updateState( { loading: 'Profiel opslaan...' } )
			await app.updateUser( input )
			alert( 'Gegevens zijn opgeslagen!' )
			history.push( '/' )

		} catch( e ) {

			log( 'saveProfile error: ', e )
			alert( `Opslag fout: ${ e.message || JSON.stringify( e ) }` )

		} finally {
			await this.updateState( { loading: false } )
		}

	}

	render() {

		const { loading, input: userState } = this.state
		const { theme, user: userProps, history } = this.props

		if( loading ) return <Loading message={ loading } />

		return <Container>
			<Navigation title='' />
			<Main.Top style={ { backgroundColor: theme.colors?.primaryLight } }>
				<SettingsPanel remoteUser={ userProps } saveProfile={ this.saveProfile } onError={ this.onError } onInput={ this.onInput } go={ to => history.push( to ) } user={ { ...userProps, ...userState } } />
			</Main.Top>
		</Container>

	}

}

export default connect( store => ( {
	user: store.user,
	theme: store.settings?.theme
} ) )( Home )