// Sentry debugging and Amplitude tracking
// import { SentryInit } from './src/modules/apis/sentry'

// React
import React from 'react'


import { View } from 'react-native'
import { Helmet } from 'react-helmet'

// Redux
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './src/redux/store'

// Import router
import Routes from './src/routes/routes'
import { History, Router } from './src/routes/router'

// Visual
import { Loading } from './src/components/stateless/common/generic'
import { setOrientation } from './src/modules/visual/screen'
import { injectWebCss } from './src/modules/visual/style'
import * as Font from 'expo-font'

// Udates
import { updateIfAvailable } from './src/modules/apis/updates'

// Devving
import { ignoreErrors } from './src/modules/helpers'

// Scroll to top on page switch
import ScrollToTop from './src/components/hook/scroll-to-top'

// Firebase dependency fix, if we ever switch to Firbease native this can be removed
import { decode, encode } from 'base-64'
if( !global.btoa ) global.btoa = encode
if( !global.atob ) global.atob = decode

// ///////////////////////////////
// Main app ( web )
// ///////////////////////////////
export default class App extends React.Component {

	
	async componentDidMount() {

		ignoreErrors( [ 'Setting a timer' ] )

		// Put upside down if developing on mobile, but not in browser
		await setOrientation()
		injectWebCss()

		// Check for updates, ask user if they want to restart
		await updateIfAvailable()

		// Init fonts
		Font.loadAsync( {
			Montserrat: require( `./assets/fonts/Montserrat-Regular.ttf` ),
			'Montserrat-Bold': require( `./assets/fonts/Montserrat-Bold.ttf` )
		} )

		// Initialise Sentry
		// SentryInit()

		// Create and store expo push token in secure storage { pushtoken }
		// await askForPushPermissions()
	}


	// Return the app with routing
	render( ) {

		return (

			<View>

				<View style={{ backgroundColor: "white", width: "100%", minHeight: "100vh" }} >

					<Provider store={ store } >

						{ /* Redux store persistence across reloads and visits */ }
						<PersistGate loading={ <Loading /> } persistor={ persistor }>
								{ /* Connect router */ }
								<Router history={ History }>
									<ScrollToTop />
									<Routes />
								</Router>
								
						</PersistGate>
					</Provider>

				</View>

				<View style={{ position: 'fixed', backgroundColor: "white", top: '50%', left: 0, bottom: '-300px', right: 0, zIndex: -1 }} />

			</View>

		)
	}

}