// import * as firebase from 'firebase'
import  firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

// Helpers
import { dev, isWeb } from '../apis/platform'
import { log } from '../helpers'

// Analytics
import * as Analytics from 'expo-firebase-analytics'
// If dev, keep analytics in dev
if( !isWeb && dev ) Analytics.setDebugModeEnabled( true )

// Redux
import { store } from '../../redux/store'
const { dispatch } = store

// Actions
import { setUserAction } from '../../redux/actions/userActions'
import { setSettingsAction } from '../../redux/actions/settingsActions'
import { setRunningSessions, setMyRunningSessions, updateRunningSessionMeta, appendRawRunningSessions } from '../../redux/actions/runningSessionsActions'
import { setSchemas } from '../../redux/actions/schemasActions'
import { setWorkouts, addWorkouts } from '../../redux/actions/workoutsActions'
import { setLeaderboard } from '../../redux/actions/leaderboardActions'


// Config
import config from './config'
import * as Network from 'expo-network'

// Functions
import { listenRunningSessions, registerForSession, listenMyRegisteredRunningSessions, unregisterForSession, loadSpecificGroupSession } from './_running-sessions'
import { listenUserLogin, listenUserChanges, registerUser, createUserWithEmailAndPasswordAndUid, loginUser, updateUser, resetPassword, logoutUser, deleteUser, getUserDetailsByUid } from './_user'
import { updateSettings, listenSettings, makeMeTrainer } from './_settings'
import { listenWorkouts, listenSchemas, registerForSchema, unregisterForSchema, completeWorkout, addWorkoutListenerIfNeeded } from './_workouts'
import { listenToLeaderboardIfNeeded } from './_leaderboard'


// ///////////////////////////////
// Firebase manager class
// ///////////////////////////////
class Firebase {

	// ///////////////////////////////
	// Set up firebase
	// ///////////////////////////////
	fb 			= firebase.initializeApp( config )
	db 			= this.fb.firestore()
	storage 	= this.fb.storage().ref()
	func 		= this.fb.functions()
	auth 		= this.fb.auth()
	listeners 	= { workouts: {} }
	FieldValue  = firebase.firestore.FieldValue
	Auth 		= firebase.auth
	analytics  	= dev ? ( {} ) : Analytics
	logs		= [ 'function, operation, amount' ]

	// System
	log = ( ...items ) => {
		if( dev ) this.logs.push( items.join( ' - ' ) )
	}

	// ///////////////////////////////
	// User actions
	// ///////////////////////////////
	registerUser  = ( name, email, pass, code ) => registerUser( this, name, email, pass, code )
	registerUserWithUid = ( name, email, pass, uid ) => createUserWithEmailAndPasswordAndUid( this, uid, name, email, pass )
	loginUser     = ( email, pass ) => loginUser( this.auth, email, pass )
	updateUser	  = userUpdates => updateUser( this, userUpdates )
	logout		  = f => logoutUser( this )
	deleteUser	  = password => deleteUser( this, password )
	resetPassword = email => resetPassword( this.auth, email )

	// ///////////////////////////////
	// Settings
	// ///////////////////////////////
	updateSettings = settings => updateSettings( this, settings )
	makeMeTrainer = user => makeMeTrainer( this, user )

	// Helpers
	isOnline = f => Network.getNetworkStateAsync().then( ( { isInternetReachable } ) => isInternetReachable ).catch( f => false )

	// ///////////////////////////////
	// Analytics
	// ///////////////////////////////
	analyticsSetScreen = path => this.analytics && this.analytics.setCurrentScreen( path ).catch( f => f )

	// ///////////////////////////////
	// group sessions
	// ///////////////////////////////
	registerForSession = id => registerForSession( this, id )
	unregisterForSession = id => unregisterForSession( this, id )
	loadSpecificGroupSession = id => loadSpecificGroupSession( this, id, dispatch, appendRawRunningSessions )

	// ///////////////////////////////
	// Training schemas
	// ///////////////////////////////
	registerForSchema = id => registerForSchema( this, id )
	unregisterForSchema = ( id, idsOfWorkouts ) => unregisterForSchema( this, id, idsOfWorkouts )
	completeWorkout = id => completeWorkout( this, id )
	addWorkoutListenerBySchemaIdIfNeeded = schemaId => addWorkoutListenerIfNeeded( this, schemaId, dispatch, addWorkouts )
	// getWorkoutsBySchema = schemaId => getWorkoutsBySchema( this, getWorkoutsBySchema )
	// getWorkoutById = workoutId => getWorkoutById( this, getWorkoutById )

	// ///////////////////////////////
	// Leaderboard
	// ///////////////////////////////
	listenToLeaderboardIfNeeded = f => listenToLeaderboardIfNeeded( this, dispatch, setLeaderboard )

	// ///////////////////////////////
	// User management
	// ///////////////////////////////
	getUserDetailsByUid = uid => getUserDetailsByUid( this, uid )

	// ///////////////////////////////
	// Testing data
	// ///////////////////////////////
	createTestData = this.func.httpsCallable( 'createDemoDataForMe' )
	createTestDataWithMeAsTrainer = this.func.httpsCallable( 'createDemoDataForMeAsTrainer' )
	deleteTestData = this.func.httpsCallable( 'deleteMyDemoData' )

	// ///////////////////////////////
	// Admin actions
	// ///////////////////////////////
	deleteAndBackupOrphanWorkouts = this.func.httpsCallable( 'deleteAndBackupOrphanWorkouts' )

	// ///////////////////////////////
	// Initialisation
	// ///////////////////////////////

	// Register user listener in a promise wrapper that resolved when initial auth state is received
	init = async history => {

		// Keep a reference to the history object
		if( history ) this.history = history

		// If emulator requested
		if( location.href.includes( 'emulator=true' ) ) {
			this.db.useEmulator( "localhost", 8080 )
			this.auth.useEmulator('http://localhost:9099/')
		}

		// Log firebase queries
		setTimeout( f => log( 'Firestore profile log: ', this.logs ), 1000 * 10 )

		this.listeners.auth = await listenUserLogin( this, dispatch, setUserAction, [
			{ name: 'profile', listener: listenUserChanges, action: setUserAction },
			{ name: 'settings', listener: listenSettings, action: setSettingsAction },
			{ name: 'runningsessions', listener: listenRunningSessions, action: setRunningSessions, metaAction: updateRunningSessionMeta },
			{ name: 'myrunningsessions', listener: listenMyRegisteredRunningSessions, action: setMyRunningSessions },
			// { name: 'allworkouts', listener: listenWorkouts, action: setWorkouts },
			{ name: 'allschemas', listener: listenSchemas, action: setSchemas }
		] )

	}

	
	

}

export default new Firebase()