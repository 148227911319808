import React from 'react'

// Visual
import { Component, Container, Loading, Main } from '../../stateless/common/generic'
import { OnboardHeading, Welcome, Photo, Frequency, SelectedSchema, Distance, CurrentDistance } from '../../stateless/onboarding/guided-onboarding'
// import Navigation from '../common/navigation'

// Data
import { log, catcher, getuid } from '../../../modules/helpers'
import app from '../../../modules/firebase/app'
import * as ImageManipulator from 'expo-image-manipulator'

// Redux
import { connect } from 'react-redux'

class GuidedOnboarding extends Component {

	state = {
		loading: false,
		input: {
			frequency: this.props?.user?.goals?.frequency || 1,
			focuslist: this.props?.user?.goals?.focuslist || [],
			distance: this.props?.user?.goals?.distance || 5
		},
		steps: [ 'welcome', 'photo', 'frequency', 'currentdistance', 'distance', 'selectedschema' ],
		single: this.props?.match?.params?.single
	}

	componentDidMount = f => this.go( this.props.match?.params?.step || 'welcome' )

	nextStep = f => {

		const { steps, single } = this.state
		const { match: { params: { step } }, user } = this.props
		let newStep = steps[ steps.indexOf( step ) + 1 ]

		// if this is a trainer, skip user settings
		if( user.isTrainer && newStep == 'frequency' ) return this.saveProfile( )

		return single ? this.saveProfile('/settings') : this.go( newStep )

	}

	go = to => this.props?.history?.push( `/onboard/${ to }` )

	onInput = ( key, value ) => this.updateState( { input: { ...this.state.input, [key]: value } } )

	registerForClosestSchema = async f => {

		const { frequency, distance } = this.state.input
		let { schemas } = this.props

		// Debugging
		if( !schemas ) {
			log( `No raw schemas found through redux: `, this.props )
			schemas = []
		}

		// If user did not select the needed prefs, do nothing
		if( !frequency || !distance ) return log( `Undefined preferences, frequency: ${ frequency }, distance: ${ distance }` )

		// Find schema that matches preferences
		const schema = schemas.find( s => s[ 'schema-distance' ].value == distance && s[ 'schema-frequency' ].value == frequency )

		// No schema match found? Exit.
		if( !schema?.id ) return log( `No schema for match frequency: ${ frequency }, distance: ${ distance } found in `, schemas, ' props: ', this.props )

		return app.registerForSchema( schema.id )


	}

	saveProfile = async ( goTo = "" ) => {

		const { input } = this.state
		const { user, history } = this.props
		let { photo, ...goals } = input

		try {

			// Photo preprocessing
			if( photo ) {

				// Check if extension is valid
				const dataUriExt = photo.uri.match( /(?:image\/)(.*)(?:;)/ )
				const extension = dataUriExt ? dataUriExt[1] : 'jpg'
				if( ![ 'png', 'jpg', 'jpeg' ].includes( extension ) ) throw 'Please select a png or jpg image.'

				// Compress the image, setting only width in resize makes height auto
				const resize = [ { resize: { width: 500} } ]
				const options = { compress: .8 }
				photo = await ImageManipulator.manipulateAsync( photo.uri, resize, options )

				// Create file blob for upload
				const file = await fetch( photo.uri )
				photo.blob = await file.blob()

				// If extension valid, add path to avatar, extension is always jpg because of the image manipulator's jpeg output
				const path = `avatars/${ user?.uid }-${ await getuid() }.jpg`
				photo.path = path

				// Append old avatar data in order to parse it in the api module
				user.oldavatar = user?.avatar


			}

			await this.updateState( { loading: 'Voorkeuren opslaan...' } )
			await app.updateUser( { goals: goals, newavatar: photo } )
			
			if( user.isTrainer ) {
				history.push( '/' )
			}
			
			if( goTo ) {
				history.push( goTo )
			}

			await this.updateState( { loading: 'Schema uitzoeken...' } )
			await this.registerForClosestSchema()

			this.nextStep()

		} catch( e ) {
			log( 'saveProfile: ', e )
			alert( e.message || e )
		} finally {
			await this.updateState( { loading: false } )
		}

	}

	render() {

		const { loading, input } = this.state
		const { user, match } = this.props

		const { params: { step } } = match
		const { frequency, distance, focuslist, photo } = input

		if( loading ) return <Loading message={ loading } />

		return <Container>
			{ /* <Navigation title='' /> */ }
			<Main.Top>

				<OnboardHeading />

				{ step == 'welcome' && <Welcome name={ user?.name } isTrainer={ user?.isTrainer } nextStep={ this.nextStep } /> }
				{ step == 'photo' && <Photo photo={ user?.avatar?.uri }  isTrainer={ user?.isTrainer } onInput={ this.onInput } nextStep={ this.nextStep } /> }
				{ step == 'frequency' && <Frequency frequency={ frequency } onInput={ this.onInput } nextStep={ this.nextStep } /> }
				
				{ /* This component does not save any data */ }
				{ step == 'currentdistance' && <CurrentDistance distance={ distance } onInput={ this.onInput } nextStep={ this.nextStep } /> }
				
				{ /* step == 'focus' && <Focus focuslist={ focuslist } onInput={ this.onInput } nextStep={ this.nextStep } /> */ }
				{ step == 'distance' && <Distance distance={ distance } onInput={ this.onInput } nextStep={ this.saveProfile } /> }

				{ step == 'selectedschema' && <SelectedSchema /> }
				
			</Main.Top>
		</Container>

	}

}

export default connect( store => ( {
	user: store.user,
	schemas: store?.schemas?.raw || []
} ) )( GuidedOnboarding )