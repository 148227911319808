import React from 'react'

// Visual
import { Component, Container, Loading, Main } from '../../stateless/common/generic'
import { HomeScreen, AvailableSessions, DetailedSession } from '../../stateless/profile/home-screen'
import Navigation from '../common/navigation'

// Data
import { log, catcher, getShortName } from '../../../modules/helpers'
import app from '../../../modules/firebase/app'

// Redux
import { connect } from 'react-redux'

class Home extends Component {

	state = {
		loading: false,
		peopleCache: {}
	}

	componentDidMount = f => {
		const { match, history } = this.props
		if( !match?.params?.intent ) history.push( `/sessions/view` )
	}

	registerForSession = async sessionId => {

		const { history } = this.props

		try {

			await this.updateState( { loading: 'Resistreren voor sessie...' } )
			await app.registerForSession( sessionId )
			history.push( '/' )

		} catch( e ) {

			log( 'registerForSession error: ', e )

		} finally {

			await this.updateState( { loading: false } )

		}

	}

	unRegisterForSession = async sessionId => {


		try {

			log( 'Unregistering for ', sessionId )
			await this.updateState( { loading: 'Afmelden voor sessie...' } )
			await app.unregisterForSession( sessionId )

		} catch( e ) {

			log( 'registerForSession error: ', e )

		} finally {

			await this.updateState( { loading: false } )

		}

	}

	viewSession = uid => this.props.history.push( `/sessions/details/${ uid }` )

	viewPerson = async uid => {

		try {

			// If person is cached use
			const { peopleCache } = this.state
			if( peopleCache[ uid ] ) return peopleCache[ uid ]

			// If not, fetch
			const person = await app.getUserDetailsByUid( uid )
			await this.updateState( { peopleCache: { ...this.state.peopleCache, [uid]: person } } )
			return person

		} catch( e ) {
			log( 'viewPerson error: ', e )
			return uid
		}

	}

	render() {

		const { loading } = this.state
		const { user, runniningsessions } = this.props
		const { intent, selector, filter } = this.props.match?.params
		const session = intent == 'details' && runniningsessions?.raw?.find( ( { id } ) => id == selector ) || runniningsessions?.mySessions?.find( ( { id } ) => id == selector )

		if( loading ) return <Loading message={ loading } />

		return <Container>
			<Navigation title={ intent == 'view' ? `Welkom, ${ getShortName( user?.name ) }` : '' } />
			<Main.Top>
				{ intent == 'view' && <HomeScreen user={ user } viewSession={ this.viewSession } registerForSession={ this.registerForSession } unRegisterForSession={ this.unRegisterForSession } /> }
				{ intent == 'book' && <AvailableSessions user={ user } viewSession={ this.viewSession } registerForSession={ this.registerForSession } unRegisterForSession={ this.unRegisterForSession } week={ selector } year={ filter } /> }
				{ intent == 'details' && <DetailedSession registerForSession={ this.registerForSession } unRegisterForSession={ this.unRegisterForSession } viewPerson={ this.viewPerson } session={ session } goHome={ this.toggleIntent } /> }
			</Main.Top>
		</Container>

	}

}

export default connect( store => ( {
	user: store.user,
	runniningsessions: store.runniningsessions
} ) )( Home )