import { PURGE } from 'redux-persist'

export default ( state=[], action ) => {

	const { type, payload } = action

	switch( type ) {

		case "SETWORKOUTS":
			return payload

		case "ADDWORKOUTS": {

			const dedupe = state.filter( oldWorkout => {

				if( payload.find( newWorkout => newWorkout.id == oldWorkout.id ) ) return false
				return true

			} )

			return [ ...dedupe, ...payload ]

		}

		case PURGE:
			return []

		// Just return the state if no known action is specified
		default:
			return state
	}
}