// Font declarations, see App.js for options
const font = {
	normal: 'Montserrat',
	bold: 'Montserrat-Bold'
}
const fontSmoothing = {
	textRendering: 'optimizeLegibility',
	WebkitFontSmoothing: 'antialiased',
	MozOsxFontSmoothing: 'grayscale'
}

export const Light = {
	dark: false,
	colors: {
		primary: '#149BF0',
		primaryLight: 'rgba(20, 155, 240, 0.2)',
		accent: '#EF7E1F',
		background: '#E3F5FF',
		text: '#2B3747',
		placeholder: 'rgba(42, 54, 70, 0.45)',
		surface: '#ffffff',
		divider: '#EBEFF0',
		border: '#C4C4C4',
		labels: {
			neutral: '#2B3747',
			success: "#8DC63F",
			error: "red",
			subtle: "#9A9A9A"
		}
		
	},
	text: {
		title: {
			...fontSmoothing,
			fontFamily: font.normal,
			fontSize: 24
		},
		label: {
			...fontSmoothing,
			fontFamily: font.normal,
			fontSize: 16
		},
		p: {
			...fontSmoothing,
			fontFamily: font.normal,
			fontSize: 16,
			lineHeight: 30
		},
		sub: {
			...fontSmoothing,
			fontFamily: font.normal,
			fontSize: 14
		},
		small: {
			...fontSmoothing,
			fontFamily: font.normal,
			fontSize: 8
		},
		bold: {
			...fontSmoothing,
			fontFamily: font.bold
		},
		schemaTitle: {
			...fontSmoothing,
			fontFamily: font.bold,
			fontSize: 16,
			lineHeight: 20
		}
	},
	font: font,
	roundness: 3,
}
export const Dark = {
	...Light,
	dark: true
}