import React from 'react'
import { connect } from 'react-redux'
// Image specific
import * as ImgPick from 'expo-image-picker'
import * as ImageManipulator from 'expo-image-manipulator'
import { checkCameraPermissions, confirmOrAskCameraPermissions } from '../../../modules/apis/permissions'
import { Dialogue, log } from '../../../modules/helpers'

// Visual
import { Pressable, Platform } from 'react-native'
import { Component, Image, Text } from '../../stateless/common/generic'

class ShowOrPickImage extends Component {

	state = {
		imgSource: 'gallery',
		image: undefined
	}


	pickImage =  async f => {
		
		// If on mobile, offer to take a picture
		if( Platform.OS != 'web' ) {
			await Dialogue( 'Take picture', 'Do you want to take a picture or pick an existing one?', [ {
				text: 'Take picture',
				onPress: f => this.updateState( { imgSource: 'camera' } )
			}, {
				text: 'Use existing picture',
				onPress: f => this.updateState( { imgSource: 'gallery' } )
			} ] )
		}

		// Based on image source open dialog
		const { imgSource } = this.state
		let image
		if( imgSource == 'camera' ) {
			// Check current permissions
			const cameraPermission = await confirmOrAskCameraPermissions()
			if( !cameraPermission ) return Dialogue( `You did not give camera permissions, so we can't take a picture.` )
			image = await ImgPick.launchCameraAsync( )
		}
		if( imgSource == 'gallery' ) image = await ImgPick.launchImageLibraryAsync( )

		// Crop image
		await this.updateState( { image: image } )
		const { width, height } = image
		const isSquare = width == height
		const isWider = width > height
		if( !isSquare ) {

			// New sizings
			const newSize = isWider ? height : width
			const gutter = isWider ? ( width - height ) / 2 : ( height - width ) / 2

			image = await ImageManipulator.manipulateAsync( image.uri, [
				{ crop: {
					originX: isWider ? gutter : 0,
					originY: isWider ? 0 : gutter,
					width: newSize,
					height: newSize
				} }
			] )

		}

		// Send the image to the callback
		const { onSelected } = this.props
		if( image && !image.cancelled ) {
			if( onSelected ) onSelected( image )
			await this.updateState( { image: image } )
		}

		log( 'Picked image: ', image )

	}

	render( ) {

		const { image: chosenImage } = this.state
		const { style, theme, size, image, children } = this.props

		return <Pressable onPress={ this.pickImage } style={ { alignItems: 'center', justifyContent: 'center', ...style } }>
			{ ( image || chosenImage ) ? <Image style={ { height: size || 100, width: size || 100, borderRadius: '50%' } } size={ size || 100 } Source={ chosenImage?.uri || image } /> : <Text>Pick</Text> }
			{ children }
		</Pressable>

	}



}

export default connect( store => ( {
	theme: store.settings.theme
} ) )( ShowOrPickImage )