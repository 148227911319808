import { dataFromSnap } from './helpers'
import { log } from '../helpers'

export const listenRunningSessions = async ( app, dispatch, action, metaAction ) => {

	const { db, auth } = app

	// Cache the documents we will load for meta data
	const cache = {}

	// Helper function to 
	const enrichSessionData = async doc => {

		try {

			let sessions = dataFromSnap( doc, true )

			// Get the meta data of the sessions		
			const richSessions = []

			// Sequentially go over sessions so that the cache can work
			for ( let i = sessions.length - 1; i >= 0; i-- ) {

				// Needed vatiables
				const session = sessions[i]
				const companyDocumentId = session[ 'groepssessie-company' ]?.value
				const trainerDocumentId = session[ 'groepssessie-trainer' ]?.value

				// log( companyDocumentId, trainerDocumentId, session )

				// get the company details
				const company 		= companyDocumentId && ( cache[ companyDocumentId ] || await db.collection( 'backendData' ).doc( companyDocumentId ).get( ).then( dataFromSnap ) )
				const trainer 		= trainerDocumentId && ( cache[ trainerDocumentId ] || await db.collection( 'backendData' ).doc( trainerDocumentId ).get( ).then( dataFromSnap ) )
				const registrations = session?.id 		&& ( cache[ session.id ] 		|| await db.collection( 'groupSessions' ).doc( session.id ).get( ).then( dataFromSnap ) )

				// set the new data to cache
				cache[ companyDocumentId ] = company
				cache[ trainerDocumentId ] = trainer
				cache[ session.id ] = registrations

				// add rich data to session list
				richSessions.push( { ...session, participants: registrations.participants, company: company, trainer: trainer } )
			}

			await dispatch( action( richSessions, auth?.currentUser?.uid ) )

			// Set listeners for group session registrations
			listenRunningSessionBookings( app, sessions.map( ( { id } ) => id ), dispatch, metaAction )

			// Log query listens, which is one read and 3 extra data points
			app.log( `listenRunningSessions, read, ${ sessions.length * 4 }` )

		} catch( e ) {

			log( 'listenRunningSessions error: ', e )

		}

	}

	try {

		// Grab the company data needed to get sessions of this user
		const { companyCode } = await db.collection( 'users' ).doc( auth.currentUser.uid ).get().then( dataFromSnap ) || {}

		// Temp fix: trim the code because it had a space
		const [ company ] = companyCode ? await db.collection( 'backendData' ).where( 'company-code.value', '==', companyCode.trim() ).get().then( dataFromSnap ) : []

		log( `Listening to running sessions for user ${ auth.currentUser.uid }, company: `, companyCode, company )

		// Listen to sessions of my company
		const listeners = []
		if( companyCode && company ) listeners.push( db.collection( 'backendData' ).where( 'type', '==', 'groepssessie' ).where( 'groepssessie-company.value', '==', company.id ).orderBy( 'sortingMeta.date', 'desc' ).limit( 100 ).onSnapshot( enrichSessionData ) )
		listeners.push( db.collection( 'backendData' ).where( 'type', '==', 'groepssessie' ).where( 'groepssessie-trainer.value', '==', auth.currentUser.uid ).orderBy( 'sortingMeta.date', 'desc' ).limit( 100 ).onSnapshot( enrichSessionData ) )

		return listeners

	} catch( e ) {
		log( 'listenRunningSessions error level one: ', e )
	}

}

export const listenRunningSessionBookings = async ( app, sessionIds=[], dispatch, action ) => {

	const { db, listeners } = app

	// Unregister old listeners
	if( listeners.sessionbookings ) {
		listeners.sessionbookings.map( listener => listener() )
		delete app.listeners.sessionbookings
	}

	// Regist array of unlisteners with app object
	app.listeners.sessionbookings = sessionIds.map( sessionId => {

		// Return listener with unlistener
		return db.collection( 'groupSessions' ).doc( sessionId ).onSnapshot( async doc => {

			// Log query listens
			app.log( `listenRunningSessionBookings, read,1` )

			return dispatch( action( dataFromSnap( doc ) ) )
		} )

	} )

}


export const listenMyRegisteredRunningSessions = ( app, dispatch, action ) => {

	const { db, auth } = app

	const addToRedux = async ( snap, iAmTrainer ) => {


		try {

			let mySessions = dataFromSnap( snap, false )
			mySessions = mySessions.map( session => ( { ...session, iAmTrainer: iAmTrainer } ) )

			// Log query listens
			app.log( `listenMyRegisteredRunningSessions, read, ${ mySessions.length }` )

			await dispatch( action( mySessions, iAmTrainer ) )

		} catch( e ) {

			log( 'listenRunningSessions error: ', e )

		}

	}

	return [
		db.collection( 'groupSessions' ).where( 'participants', 'array-contains', auth.currentUser.uid ).where( 'date', '>', Date.now() ).onSnapshot( snap => addToRedux( snap ) ),
		db.collection( 'backendData' ).where( 'type', '==', 'groepssessie' ).where( 'groepssessie-trainer.value', '==', auth.currentUser.uid ).orderBy( 'sortingMeta.date', 'desc' ).limit( 100 ).onSnapshot( snap => addToRedux( snap, true ) )
	]


}

export const loadSpecificGroupSession = async ( app, sessionUid, dispatch, action ) => {

	const { db, auth } = app

	try {

		const session = await db.collection( 'backendData' ).doc( sessionUid ).get().then( dataFromSnap )

		// Needed vatiables
		const companyDocumentId = session[ 'groepssessie-company' ]?.value
		const trainerDocumentId = session[ 'groepssessie-trainer' ]?.value

		// log( companyDocumentId, trainerDocumentId, session )

		// get the company details
		const company 		= companyDocumentId && await db.collection( 'backendData' ).doc( companyDocumentId ).get( ).then( dataFromSnap )
		const trainer 		= trainerDocumentId && await db.collection( 'backendData' ).doc( trainerDocumentId ).get( ).then( dataFromSnap )
		const registrations = session?.id 		&& await db.collection( 'groupSessions' ).doc( session.id ).get( ).then( dataFromSnap )

		const richSession = { ...session, participants: registrations.participants, company: company, trainer: trainer }

		await dispatch( action( [ richSession ] ) )

		// add rich data to session list
		return richSession

	} catch( e ) {
		log( 'loadSpecificGroupSession error: ', e )
	}

}

export const registerForSession = async ( app, sessionId ) => {

	const { auth: { currentUser }, db, FieldValue } = app

	try {

		await db.collection( 'groupSessions' ).doc( sessionId ).set( { participants: FieldValue.arrayUnion( currentUser.uid ) }, { merge: true } )

	} catch( e ) {

		log( 'registerForSession error: ', e )
		log( `When adding ${ currentUser.uid } to ${ sessionId } in groupSessions` )

	} finally {

		// Log query
		app.log( `registerForSession, write, 1` )

	}

}

export const unregisterForSession = async ( app, sessionId ) => {

	const { auth: { currentUser }, db, FieldValue } = app

	try {

		await db.collection( 'groupSessions' ).doc( sessionId ).set( { participants: FieldValue.arrayRemove( currentUser.uid ) }, { merge: true } )
		log( 'Removed ', currentUser.uid, ' from ', sessionId )
	} catch( e ) {
		log( 'registerForSession error: ', e )
	} finally {

		// Log query
		app.log( `unregisterForSession, write, 1` )

	}

}